import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import { helpers } from "../../helpers";

type Props = {
  product: any;
  onTap?: (product: any) => void;
  className?: string;
};
export default function ProductCard({ product, onTap, className }: Props) {
  return (
    <Card sx={{ maxWidth: "100%" }} className={`!rounded-lg !bg-neutral-50 !shadow-md ${className}`} onClick={() => onTap && onTap(product)}>
      <CardActionArea className="!h-full">
        <div className="flex flex-row w-full h-full gap-x-3">
          <div className="ImgLike w-32 h-full min-h-32 left-0 top-0 bg-zinc-400 rounded-lg">
            <img
              className="ImgEcheveria w-32 h-full  rounded-lg"
              src={helpers.getFirstImage(product.images)}
              alt="Product"
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-2">
            <div className="text-black font-medium leading-tight w-full">
              {product.name}
            </div>
            <div className="text-black text-xs font-light leading-3 w-full">
              Available: {product.quantity}
            </div>
            <div className="N25000 bg-black text-white text-base font-medium py-0.5 px-3">
              N{helpers.money(product.price)}
            </div>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}
