import { useContext, useEffect, useState } from "react";
import {
  DocumentData,
  OrderByDirection,
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../..";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";

type UseFireDocProps = {
  docId: string;
  initialize?: boolean;
};
const useClient = ({ docId, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async ({ newDocId = null }) => {
    setLoading(true);
    const docRef = doc(db, "clients", (newDocId || docId)!);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setClient({ ...docSnap.data(), id: docSnap.id });
    } else {
      console.log("No such client!");
    }
    setLoading(false);
  };

  return {
    loading,
    client,
    setClient,
    runQuery,
  };
};

type UseFireDocsProps = {
  initialize?: boolean;
  addLimit?: number;
  addOrderBy?: string;
  addOrderDirection?: OrderByDirection;
  search?: string;
  ids?: any[];
  paginate?: boolean;
  addPage?: number;
  dateFrom?: string;
  dateTo?: string;
};
const useClients = ({
  addOrderBy = "created_at",
  addOrderDirection = "desc",
  addLimit = 20,
  initialize = false,
  search = "",
  ids = [],
  addPage = 0,
  paginate = false,
  dateFrom = "",
  dateTo = "",
}: UseFireDocsProps) => {
  const context: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([] as any);
  const [lastDocs, setLastDocs] = useState([] as any);
  const [page, setPage] = useState(addPage);
  const [currentLimit, setCurrentLimit] = useState(addLimit);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type RunQueryProps = {
    newAddOrderBy?: string;
    newAddOrderDirection?: OrderByDirection;
    newAddLimit?: number;
    newPage?: number;
    newPaginate?: boolean;
    newSearch?: string;
  };
  const runQuery = async ({
    newAddOrderBy,
    newAddOrderDirection,
    newAddLimit,
    newPage,
    newPaginate,
    newSearch,
  }: RunQueryProps) => {
    if (newPage !== undefined && newPage !== null) {
      setPage(newPage);
    }
    if (newAddLimit) {
      setCurrentLimit(newAddLimit);
    }

    setLoading(true);
    const docRef = collection(db, "clients");
    var queries: any[] = [];

    //customers_manager can see all clients
    if (context.user.role !== "customers_manager") {
      //where manager_id == context.user?.id or access == public
      if ((newSearch || search).length > 0) {
        //cannt use or() with array-contains-any
        queries.push(
          and(
            or(
              where("manager_id", "==", context.user.id),
              where("access", "==", "public")
            ),
            where("search_keywords", "array-contains-any", [
              (newSearch || search).toLowerCase(),
            ])
          )
        );
      } else {
        queries.push(
          or(
            where("manager_id", "==", context.user.id),
            where("access", "==", "public")
          )
        );
      }
    } else {
      //  search in search_keywords
      if ((newSearch || search).length > 0) {
        var searchList = helpers.generateLiteSearchKeywords(
          (newSearch || search).toLowerCase()
        );
        queries.push(
          where("search_keywords", "array-contains-any", searchList)
        );
      }
    }

    //dateFrom
    if (dateFrom.length > 0) {
      queries.push(where("created_at", ">=", dateFrom));
    }

    //dateTo
    if (dateTo.length > 0) {
      queries.push(where("created_at", "<=", dateTo));
    }

    //ids
    if (ids.length > 0) {
      //make sure orderBy query is removed above
      queries.push(where("doc_number", "in", ids));
    }

    //orderBy
    queries.push(
      orderBy(
        newAddOrderBy || addOrderBy,
        newAddOrderDirection || addOrderDirection
      )
    );

    // if page index exists, get last doc from lastDocs
    if (lastDocs[(newPage || page) - 1] && (newPaginate || paginate)) {
      queries.push(startAfter(lastDocs[(newPage || page) - 1]));
    }

    if (currentLimit > 0) {
      queries.push(limit(newAddLimit || currentLimit));
    }

    const q = query(docRef, ...queries);
    const docsSnap = await getDocs(q);

    var lastDoc = docsSnap.docs[docsSnap.docs.length - 1];
    //check if lastDoc already exists in lastDocs
    if (!lastDocs.includes(lastDoc) && lastDoc) {
      //check if lastDoc.id is not in any of the lastDocs
      if (!lastDocs.some((doc: any) => doc.id === lastDoc.id)) {
        var lists: any[] = lastDocs;
        lists.push(lastDoc);
        setLastDocs(lists);
      }
    }

    const docs: DocumentData[] = [];
    docsSnap.forEach((doc) => {
      const data: any = { ...doc.data(), id: doc.id };
      docs.push(data);
    });
    setClients(docs);

    setLoading(false);
  };

  const deleteClient = async (id: string) => {
    const docRef = doc(db, "clients", id);
    await deleteDoc(docRef);
    //remove from clients
    var newClients = clients.filter((item: any) => item.id !== id);
    setClients(newClients);
  };

  const resetPagination = () => {
    setPage(0);
    setLastDocs([]);
  };

  const nextPage = () => {
    runQuery({ newPage: page + 1, newPaginate: true });
  };

  return {
    loading,
    clients,
    setClients,
    runQuery,
    resetPagination,
    nextPage,
    page,
    currentLimit,
    deleteClient,
  };
};

export { useClient, useClients };
