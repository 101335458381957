import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { useCartContext } from "../hooks/useCart";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { helpers } from "../../helpers";
import { Shimmer } from "react-shimmer";
import CartItemCard from "../components/CartItemCard";

export default function AppCart() {
  const navigate = useNavigate();

  const cartContext = useCartContext();

  useEffect(() => {
    cartContext.fetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* header */}
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full">
            Cart
          </p>
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {/* <SvgIcon fontSize="large"> */}
                {/* credit: plus icon from https://heroicons.com/ */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-10 h-10"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                {/* </SvgIcon> */}
              </IconButton>
            </li>
          </ul>
        </div>
      </header>
      <div className="mt-[90px]"></div>
      {cartContext.loading ? (
        <ShimmerPage />
      ) : (
        <div className="px-4 sm:grid sm:grid-cols-2 sm:gap-x-10 sm:px-20">
          <div>
            {/* empty cart placeholder */}
            {(cartContext.cart.items?.length < 1 || !cartContext?.cart?.items) && (
              <div className="flex flex-col items-center justify-center gap-y-2 mt-[150px] mb-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[80px] h-[80px] text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  />
                </svg>
                <div className="text-2xl text-gray-400 font-normal">
                  Cart is empty
                </div>
              </div>
            )}

            {/* cart items */}
            {cartContext.cart.items?.length > 0 && (
              <div className="mb-4 space-y-2">
                {cartContext.cart.items.map((item: any) => {
                  return (
                    <CartItemCard
                      key={item.id}
                      item={item}
                      onTap={(item) => {}}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div>
            {/* total */}
            {cartContext.totalLoading ? (
              <Shimmer
                height={40}
                width={500}
                className="!rounded-lg !w-full !my-4"
              />
            ) : (
              cartContext.cart?.items?.length > 0 && (
                <div className="flex flex-row items-center justify-between gap-x-2 my-2">
                  <div className="text-xl font-medium leading-tight w-full">
                    Total
                  </div>
                  <div className="text-2xl font-bold py-0.5 px-3">
                    N{helpers.money(cartContext.cart.total)}
                  </div>
                </div>
              )
            )}

            {/* checkout button */}
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px] !mt-2"
              onClick={() => {
                if (
                  cartContext.cart.items?.length < 1 ||
                  cartContext.loading ||
                  cartContext.totalLoading ||
                  cartContext.cart.total < 1 ||
                  !cartContext?.cart?.items
                ) {
                  return;
                }
                navigate(`/app/addresses`);
              }}
              disabled={
                cartContext.cart.items?.length < 1 ||
                cartContext.loading ||
                cartContext.totalLoading ||
                cartContext.cart.total < 1 ||
                !cartContext?.cart?.items
              }
            >
              Checkout
            </Button>
          </div>
        </div>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

const ShimmerPage = () => {
  return (
    <>
      {/* page */}
      <div className="px-4 space-y-4 mt-[100px] sm:grid sm:grid-cols-2 sm:gap-x-10 sm:px-20">
        <div>
          {/* cart items */}
          <div className="space-y-2">
            {[1, 2, 3, 4].map((item: any) => {
              return (
                <Shimmer
                  key={item}
                  width={130}
                  height={120}
                  className="rounded-lg !w-full"
                />
              );
            })}
          </div>
        </div>
        <div className="sm:flex sm:flex-col sm:gap-y-5">
          {/* cart total */}
          <Shimmer width={130} height={50} className="rounded-lg !w-full" />
          {/* checkout button */}
          <Shimmer
            width={130}
            height={50}
            className="rounded-lg !w-full !h-[60px]"
          />
        </div>
      </div>
    </>
  );
};
