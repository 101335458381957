import React, { useContext, useImperativeHandle, useState } from "react";
import { helpers } from "../helpers";
import Currency from "./Currency";
import StatusLabel from "./StatusLabel";
import CircularLoader from "./CircularLoader";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "..";
import { confirm } from "react-confirm-box";
import OrderModal from "../pages/modal/OrderModal";
import AppContext from "../AppContext";
import { Link } from "react-router-dom";

type Props = {
  orders: any[];
  onTableUpdated: (order: any) => void;
  loading?: boolean;
  className?: string;
  headingTitle?: string;
  showViewAll?: boolean;
  refx?: any;
};
const OrdersTable = ({ orders, onTableUpdated, loading = false, className, headingTitle, showViewAll, refx }: Props) => {

  useImperativeHandle(refx, () => ({
    setOrder,
    setShowModal
}));

  const context: any = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState({});

  const onModalUpdated = (order: any) => {
    onTableUpdated(order);
  };

  const deleteOrder = async (order: any) => {
    //only admin, super_admin can delete orders
    if (!([ 'admin', 'super_admin' ].includes(context.user.role))) {
      toast.error("You are not authorized to perform this action!");
      return;
    }
    //confirm delete
    const proceed = await confirm(
      `Are you sure you want to delete ${order.name}?`
    );
    if (!proceed) return;

    const loading = toast.loading("Please wait...");

    const orderRef = doc(db, "orders", order.id);

    await deleteDoc(orderRef)
      .then(() => {
        onTableUpdated(null);
        toast.success("Deleted successfully");
      })
      .catch((error) => {
        toast.error("Failed to delete");
      });

    toast.dismiss(loading);
  };

  return (
    <>

    {/* mobile heading */}
    <div className="flex items-center justify-between px-5 lg:hidden">
        <h2 className="text-md font-medium text-gray-700 dark:text-gray-200">
            {headingTitle || "Orders"}
        </h2>
        { showViewAll && (
        <div className="flex items-center">
            <Link to="/admin/orders" className="text-gray-800 shadow-sm px-2 py-1 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-500">
                View all
            </Link>
        </div>
        )}
      </div>
    
      {/* <!-- New Table --> */}
      <div className={`w-full overflow-hidden rounded-lg shadow-xs mt-2 ${className}`}>
        <div className="w-full overflow-x-auto">
          <table className="container w-full whitespace-no-wrap # flex flex-row flex-no-wrap lg:bg-white rounded-lg overflow-hidden lg:shadow-lg my-5">
            <thead>
              {/* hide head for mobile */}
              <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 # hidden ?flex flex-col flex-no wrap lg:table-row rounded-l-lg lg:rounded-none mb-2 lg:mb-0">
                <th className="px-4 py-3">Order</th>
                <th className="px-4 py-3">Sales Manager</th>
                {/* only admin, super_admin, accounting_manager can see prices */}
                {
                  (['admin', 'super_admin', 'accounting_manager'].includes(context.user?.role)) && (
                <th className="px-4 py-3">Amount</th>
                  )
                }
                <th className="px-4 py-3">Status</th>
                <th className="px-4 py-3">Date</th>
                <th className="px-4 py-3 text-right">Action</th>
              </tr>
              
            </thead>
            <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 # flex-1 lg:flex-none">
              {loading && (
                <tr className="text-gray-700 dark:text-gray-400 # flex flex-col flex-no wrap lg:table-row mb-2 lg:mb-0 rounded-xl lg:rounded-none shadow lg:shadow-none hover:shadow-lg lg:hover:shadow-none border">
                  <td className="px-4 py-3 text-sm" colSpan={6}>
                    <div className="flex justify-center items-center">
                      <CircularLoader />
                      <span>Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {orders.length > 0 &&
                !loading &&
                orders.map((order) => (
                  <tr
                    className="text-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer # flex flex-col flex-no wrap lg:table-row mb-2 lg:mb-0 rounded-xl lg:rounded-none shadow lg:shadow-none hover:shadow-lg lg:hover:shadow-none border"
                    onClick={() => {
                      setOrder(order);
                      setShowModal(true);
                    }}
                    key={order.id}
                  >
                    <td className="px-4 py-3">
                      <div className="flex items-center text-md lg:text-sm">
                        {/* <!-- Avatar with inset shadow --> */}
                        <div className="relative hidden w-8 h-8 mr-3 rounded-full lg:block">
                          <img
                            className="object-cover w-full h-full rounded-full"
                            src={helpers.getFirstImage(
                              order.client.avatar,
                              "avatar",
                              order.client.full_name
                            )}
                            alt=""
                            loading="lazy"
                          />
                          <div
                            className="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                          ></div>
                        </div>
                        <div className="hover:text-pink-800 hover:underline hover:cursor-pointer space-y-2 lg:space-y-0"
                        >
                          {/* ORDER ID */}
                          <p className="text-xs inline lg:block">
                            #{order.doc_number || order.id}
                            {/* if order has backorder */}
                            {
                              (() => {
                                var totalBackorders = 0;
                                order.items?.forEach((item: any) => {
                                  if (item.backorder_quantity > 0) {
                                    totalBackorders += item.backorder_quantity ?? 0;
                                  }
                                });

                                // if there are backorders and order is not completed
                                if (totalBackorders > 0 && order.status !== "completed") {
                                  return (
                                    <span className="text-xs bg-red-600 text-white rounded-full px-2 py-0.5 ml-2 whitespace-nowrap">
                                      {totalBackorders} Backorders
                                    </span>
                                  );
                                }

                              })()
                            }
                            {/* if order_type is a custom */}
                            {
                              order.order_type === "custom" && (
                                <span className="text-xs bg-green-600 text-white rounded-full px-2 py-0.5 ml-2">
                                  Custom Order
                                </span>
                              )
                            }
                          </p>
                          <p className="font-semibold lg:whitespace-nowrap inline lg:block ml-1 lg:ml-0">
                            {helpers.capitalizeEachWord(order.client.full_name)}
                          </p>
                          <p className="text-xs text-gray-600 dark:text-gray-400">
                            {order.client.address}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 lg:py-3 text-xs lg:text-sm">
                      <span className="lg:hidden text-gray-400">Rep: </span>
                      {order.sales_manager.full_name}
                    </td>
                    {/* only admin, super_admin, accounting_manager can see prices */}
                    {
                      (['admin', 'super_admin', 'accounting_manager'].includes(context.user?.role)) && (
                        <td className="px-4 py-3 text-sm font-bold text-right lg:text-left">
                          <Currency />
                          {helpers.money(order.total)}
                        </td>
                      )
                    }
                    <td className="px-4 lg:py-3 text-xs text-right lg:text-left">
                      <StatusLabel status={order.status} />
                    </td>
                    <td className="px-4 py-3 text-sm">
                      {order?.created_at?.toDate()?.toLocaleDateString()}
                    </td>
                    <td className="px-4 py-3  text-sm text-right pt-0 lg:pt-4 flex items-center justify-end">
                      <button
                        onClick={() => {
                          setOrder(order);
                          setShowModal(true);
                        }}
                        id="row-edit"
                        className="inline bg-black rounded text-white p-2 hover:bg-gray-700 disabled:bg-gray-800"
                        data-modal-toggle="edit-modal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 icon icon-tabler icon-tabler-pencil"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <desc>
                            Download more icon variants from
                            https://tabler-icons.io/i/pencil
                          </desc>
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                          <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                        </svg>
                      </button>
                      {/* only admin, super_admin can delete orders */}
                      {
                        ([ 'admin', 'super_admin' ].includes(context.user.role)) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteOrder(order);
                        }}
                        className="inline text-center bg-red-700 rounded text-white p-2 hover:opacity-80 ml-2"
                      >
                        <svg
                          id="icon"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 icon icon-tabler icon-tabler-trash"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <desc>
                            Download more icon variants from
                            https://tabler-icons.io/i/trash
                          </desc>
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <line x1="4" y1="7" x2="20" y2="7"></line>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                        <svg
                          id="loading"
                          className="hidden animate-spin h-4 w-4 icon icon-tabler text-white"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </button>
                        )
                      }
                    </td>
                  </tr>
                ))}
              {orders.length === 0 && !loading && (
                <tr className="text-gray-700 dark:text-gray-400">
                  <td className="px-4 py-3 text-sm text-center" colSpan={6}>
                    No orders yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 lg:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
          <span className="flex items-center col-span-3">
            Showing {orders.length} of {orders.length}
          </span>
          <span className="col-span-2"></span>
          {/* <!-- Pagination --> */}
          {/* <span className="flex col-span-4 mt-2 lg:mt-auto lg:justify-end">
                        <nav aria-label="Table navigation">
                            <ul className="inline-flex items-center">
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple" aria-label="Previous">
                                        <svg aria-hidden="true" className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        1
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        2
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 text-white transition-colors duration-150 bg-purple-600 border border-r-0 border-purple-600 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        3
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        4
                                    </button>
                                </li>
                                <li>
                                    <span className="px-3 py-1">...</span>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        8
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-purple">
                                        9
                                    </button>
                                </li>
                                <li>
                                    <button className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-purple" aria-label="Next">
                                        <svg className="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                                            <path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" fillRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </span> */}
        </div>
      </div>

      <OrderModal
        showModal={showModal}
        setShowModal={setShowModal}
        order={order}
        setOrder={setOrder}
        onModalUpdated={onModalUpdated}
      />
    </>
  );
};

export default OrdersTable;
