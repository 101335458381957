import { useClients } from "../hooks/useClients";
import { Shimmer } from "react-shimmer";
import SimpleClientCard from "../components/SimpleClientCard";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";

export default function AppClients() {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const { clients, loading, runQuery } = useClients({ initialize: true });

  return (
    <>
      <header className="z-30 fixed top-0 left-0 w-full py-4 bg-white shadow-md dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
          {/* <!-- Search input --> */}
          <div className="flex justify-center flex-1">
            <div className="relative w-full max-w-xl mr-2 focus-within:text-purple-500">
              <div className="absolute inset-y-0 flex items-center pl-2">
                <svg
                  className="w-4 h-4 text-gray-400"
                  area-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                className="w-full pl-8 py-2 pr-2 text-lg text-gray-700 placeholder:text-gray-400 bg-white border border-gray-400 rounded-lg dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input"
                type="text"
                placeholder="Search clients"
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    runQuery({ newSearch: search });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </header>

      <div className="mt-[80px]"></div>

      {/* page */}
      <div className="px-4 py-4 sm:px-20">
        {loading ? (
          <ShimmerPage />
        ) : (
          <>
            {/* empty clients placeholder */}
            {clients.length < 1 && (
              <div className="flex flex-col items-center justify-center gap-y-2 mt-[150px] mb-[100px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[80px] h-[80px] text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>

                <div className="text-2xl text-gray-400 font-normal">
                  No clients found
                </div>
              </div>
            )}
            {clients.length > 0 && (
              <>
              {/* show total */}
              <div className="text-gray-600 text-right">
                Showing {clients.length} of {clients.length} clients
              </div>

              <div className="space-y-5 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-3">
                {clients.map((client: any) => {
                  return (
                    <SimpleClientCard
                      key={client.id}
                      client={client}
                      onTap={(client) => {
                        navigate(`/app/edit-client?client=${client.id}`);
                      }}
                    />
                  );
                })}
              </div>
              </>
            )}
          </>
        )}
      </div>
    
      {/* floating button */}
      <IconButton
        className="!fixed !bottom-[90px] !right-0 !m-4 !bg-black !z-[1000]"
        color="primary"
        size="large"
        onClick={() => {
            navigate("/app/edit-client");
        }}
      >
        <Add className="!text-white" fontSize="large" />
      </IconButton>
    </>
  );
}

const ShimmerPage = () => {
  return (
    <>
      <div className="space-y-4 sm:space-y-0 w-full sm:grid sm:grid-cols-2 sm:gap-3">
        {[1, 2, 3, 4].map((item: any) => {
          return (
            <Shimmer
              key={item}
              width={130}
              height={90}
              className="rounded-lg !w-full"
            />
          );
        })}
      </div>
    </>
  );
};
