/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import CategoriesTable from "../components/CategoriesTable";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";



const Categories = () => {
    useDocumentTitle('Categories');

    const [categories, setCategories] = useState([] as any[]);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const fetchCategories =  async () => {
        setCategoriesLoading(true);
        let categories: any[] = [];
        //fetch orders from firebase
        const categoriesRef = collection(db, 'categories');
        const q = query(categoriesRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                categories.push({ ...doc.data(), id: doc.id });
            });
        });
        setCategories(categories);
        setCategoriesLoading(false);
    };

    useEffect(() => {
        fetchCategories();
    }, []);


    const onTableUpdated = (category: any) => {
        fetchCategories();
    }


    return (
        <>

            <MainTemplate title="Categories">


               

                {/* <!-- Categories --> */}
                <CategoriesTable categories={categories} onTableUpdated={onTableUpdated} loading={categoriesLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Categories;