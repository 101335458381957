import { Card, CardActionArea } from "@mui/material";
import { helpers } from "../../helpers";

type Props = {
  payment: any;
  onTap?: (payment: any) => void;
};
export default function InvoiceCard({ payment, onTap }: Props) {
  return (
    <Card
      sx={{ maxWidth: "100%" }}
      className="!rounded-0 !shadow-sm !border !border-gray-100"
      onClick={() => onTap && onTap(payment)}
    >
      <CardActionArea>
        <div className="flex flex-row w-full gap-x-3 bg-neutral-50 p-3">
          <img
            src="/assets/images/img_arrowdown_black_900.svg"
            alt="icon"
            className="w-12 h-12 rounded-lg"
          />
          <div className="flex flex-row w-full gap-x-3 justify-between">
            <div className="flex flex-col items-start">
              <div>#{payment.doc_number}</div>
              <div className="font-semibold text-sm">{payment.client?.full_name}</div>
            </div>
            <div className="flex flex-col items-start">
              <div className="font-bold">N{helpers.money(payment.amount)}</div>
              <div className="text-gray-500">
                {payment.created_at.toDate().toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}
