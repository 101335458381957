import { useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import { TagsInput } from "react-tag-input-component";

type Props = {
    title?: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    attribute?: any;
    setAttribute: (attribute: any) => void;
    onModalUpdated: (attribute: any) => void;
}
const AttributeModal = ({ title = "New Attribute", showModal, setShowModal, attribute, setAttribute, onModalUpdated }: Props) => {

    const [attributeData, setAttributeData] = useState(attribute ?? {});
    const [options, setOptions] = useState([] as string[]);

    useEffect(() => {
        setAttributeData(attribute);
        if (attribute.options) {
            setOptions(attribute.options.map((option: any) => option.label) ?? []);
        }
    }, [attribute, attributeData.options]);

    const onInputChanged = (e: any) => {
        setAttributeData({ ...attributeData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Please wait...");

        let formData: any = { ...attributeData };
        formData.options =  options.map((option) => ({ label: option, value: option }));

        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            delete formData.slug;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);

            //add to firebase
            const attributeRef = doc(db, "attributes", attributeData.id);
            await updateDoc(attributeRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(attributeRef);
                if (docSnap.exists()) {
                    setAttribute({ ...docSnap.data(), id: docSnap.id });
                    setAttributeData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Updated successfully");
                } else {
                    toast.error("Failed to update");
                }
            }).catch((error) => {
                toast.error("Failed to update");
            });



        } else { //add
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);
            formData.slug = helpers.getSlug(formData.name);

            const attributesRef = collection(db, "attributes");

            //add timestamp to slug if slug already exists
            const slugQuery = query(attributesRef, where("slug", "==", formData.slug));
            const slugQuerySnapshot = await getDocs(slugQuery);
            if (slugQuerySnapshot.size > 0) {
                formData.slug += `-${formData.created_at.seconds}`;
            }

            //add to firebase
            const docRef = await addDoc(attributesRef, formData);
            if (docRef) {
                //get the updated data
                const attributeRef = doc(db, "attributes", docRef.id);
                const docSnap = await getDoc(attributeRef);
                if (docSnap.exists()) {
                    setAttribute({ ...docSnap.data(), id: docSnap.id });
                    setAttributeData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                } else {
                    toast.error("Failed to add");
                }
            } else {
                toast.error("Failed to add");
            }
        }

        toast.dismiss(loading);
        setShowModal(false);
    }

    return (
        <>
            <SkyeModal title={attributeData.id ? "Attribute" : "New Attribute"} size="max-w-sm" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form autoComplete="off" className="space-y-6" id="attribute-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative px-6 pt-6 pb-2 flex-auto">

                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                            <input
                                name="name"
                                value={attributeData.name || ""}
                                onChange={onInputChanged}
                                type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Name" required />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="options" className="block text-sm font-medium text-gray-900 dark:text-gray-300">Options</label>
                            <TagsInput
                                name="options"
                                value={options}
                                onChange={(tags) => setOptions(tags)}
                                separators={[",", "Enter"]}
                                // className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                placeHolder="Enter options" 
                                // required
                                 />
                            <em>press enter or comma to add new tag</em>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button type="submit" className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black">
                            <span id="submit-text">{attributeData.id ? "Update" : "Add"}</span>
                        </button>
                    </div>
                </form>
            </SkyeModal>
        </>
    );
}

export default AttributeModal;