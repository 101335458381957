import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Button, Checkbox, FormControlLabel, Switch, TextField } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useSmartLoadingDialogContext } from "../components/SmartLoadingDialog";
import { useContext, useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";
import { db } from "../..";

export default function EditClient() {
  const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const pageFrom = new URLSearchParams(window.location.search).get("from");

  const smartLoading = useSmartLoadingDialogContext();

  const [client, setClient] = useState({} as any);
  const [saveAsClient, setSaveAsClient] = useState(pageFrom !== "checkout"); //always save as client if pageFrom is not checkout

  const [salesManagers, setSalesManagers] = useState([] as any[]);

  const onFieldChange = (e: any) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const fetchClient = async () => {
    const clientID = new URLSearchParams(window.location.search).get("client");
    if (clientID) {
      smartLoading.show();
      const docRef = doc(db, "clients", clientID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setClient({ ...docSnap.data(), id: docSnap.id });
      } else {
        toast.error("Client not found");
      }
      smartLoading.dismiss();
    }
  };

  const fetchSalesManagers = async () => {
    const salesManagersRef = collection(db, "users");
    const q = query(salesManagersRef, where("role", "==", "sales_manager"));
    await getDocs(q).then((snapshot) => {
      var salesManagers: any[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        salesManagers.push({ ...data, id: doc.id });
      });
      setSalesManagers(salesManagers);
    });
  }

  const submit = async (e: any) => {
    e.preventDefault();

    if (client.id) {
      //update

      //only owner or customers_manager can update
      if (
        (client.manager_id !== context.user.id && context.user.role !== "customers_manager") ||
        (client.access === "private" && context.user.role !== "customers_manager")
      ) {
        toast.error("You are not authorized to update this client");
        return;
      }

      smartLoading.show();
      await updateDoc(doc(db, "clients", client.id), {
        ...client,
        search_keywords: [
          ...helpers.generateSearchKeywords(client.full_name),
          ...helpers.generateLiteSearchKeywords(client.phone),
        ],
        manager_id: client.manager_id || context.user.id,
        updated_at: serverTimestamp(),
        updated_by: {
          id: context.user.id,
          doc_number: context.user.doc_number ?? 0,
          full_name: context.user.full_name,
          email: context.user.email,
          phone: context.user.phone,
          role: context.user.role,
        },
      });
      toast.success("Client updated successfully");
      smartLoading.dismiss();
      if (pageFrom === "checkout") {
        navigate(`/app/checkout?client=${client.id}`);
      } else {
        navigate(-1);
      }
    } else if (saveAsClient) {
      //save as client
      smartLoading.show();
      const addC = await addDoc(collection(db, "clients"), {
        ...client,
        doc_number: await helpers.getNextDocumentNumber("clients"),
        search_keywords: helpers.generateSearchKeywords(client.full_name),
        manager_id: client.manager_id || context.user.id,
        manager: context.user,
        // eslint-disable-next-line eqeqeq
        access: context.user.role == "customers_manager" ? "public" : "private",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      });
      toast.success("Client added successfully");
      smartLoading.dismiss();
      if (pageFrom === "checkout") {
        navigate(`/app/checkout?client=${addC.id}`);
      } else {
        navigate(-1);
      }
    } else {
      //pass to checkout
      if (pageFrom === "checkout") {
        //form url with query params
        let url = "/app/checkout?";
        Object.keys(client).forEach((key) => {
          url += `${key}=${client[key]}&`;
        });
        navigate(url);
      } else {
        toast.error("Please save as client or select a client");
      }
    }
  };

  useEffect(() => {
    fetchClient();
    fetchSalesManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* header */}
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* back button */}
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos />
              </IconButton>
            </li>
          </ul>
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full">
            {client.id ? "Edit Client" : "Add Client"}
          </p>
        </div>
      </header>
      <div className="mt-[90px]"></div>

      <div className="px-4">
        <form
          onSubmit={submit}
          className="flex flex-col gap-y-5 sm:px-20"
          autoComplete="off"
        >
          <div className="flex flex-col gap-y-5 sm:grid sm:grid-cols-2 sm:gap-x-5">
            {/* name */}
            <TextField
              name="full_name"
              value={client.full_name || ""}
              onChange={onFieldChange}
              label="Name"
              type="text"
              placeholder="Name"
              className="!w-full"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* phone number */}
            <TextField
              name="phone"
              value={client.phone || ""}
              onChange={onFieldChange}
              label="Phone Number"
              type="number"
              placeholder="Phone Number"
              className="!w-full"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* state/province */}
            <TextField
              name="state"
              value={client.state || ""}
              onChange={onFieldChange}
              label="State/Province"
              type="text"
              placeholder="State/Province"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* address (optional) */}
            <TextField
              name="address"
              value={client.address || ""}
              onChange={onFieldChange}
              label="Street Address (Optional)"
              type="text"
              placeholder="Address"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* city (optional) */}
            <TextField
              name="city"
              value={client.city || ""}
              onChange={onFieldChange}
              label="City (Optional)"
              type="text"
              placeholder="City"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* zip code (optional) */}
            <TextField
              name="zip_code"
              value={client.zip_code || ""}
              onChange={onFieldChange}
              label="Zip Code (Optional)"
              type="text"
              placeholder="Zip Code"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* email (optional) */}
            <TextField
              name="email"
              value={client.email || ""}
              onChange={onFieldChange}
              label="Email (Optional)"
              type="text"
              placeholder="Email"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* country (optional) */}
            <TextField
              name="country"
              value={client.country || ""}
              onChange={onFieldChange}
              label="Country (Optional)"
              type="text"
              placeholder="Country"
              className="!w-full"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className={`flex flex-col`}>
            <div className="flex flex-row items-center justify-center gap-x-5">
              <div className="font-bold text-black">Is new client?</div>
              <Switch 
              checked={client.is_new || false} 
              onChange={(e) => {
                setClient({ ...client, is_new: e.target.checked });
              }}
                 />
            </div>
            <div className="text-xs text-gray-500 text-center">
              You can specify if the client is new or not
            </div>
          </div>

          {/* save as client */}
          <div
            className={`flex flex-row items-center justify-center ${
              (client.id || pageFrom !== "checkout") && "hidden"
            }`}
          >
            <FormControlLabel
              control={
                <Checkbox
                  //   if client has id or saveAsClient is true, then check the checkbox
                  checked={(client.id || saveAsClient) && true}
                  onChange={(e) => {
                    setSaveAsClient(e.target.checked);
                  }}
                  color="primary"
                />
              }
              label="Save as Client"
            />
          </div>

          {/* assign sales manager */}
          <div
            className={`flex flex-col ${
              context.user.role === "customers_manager" ? "" : "hidden"
            }`}
          >
            <div className="font-bold text-black">Assign Sales Manager</div>
            <select
              name="manager_id"
              value={client.manager_id || ""}
              onChange={onFieldChange}
              className="!w-full !px-4 !py-2 !rounded-lg !border-2 !border-gray-300 !focus:outline-none !focus:border-purple-500"
            >
              <option value="">Select Sales Manager</option>
              {salesManagers.map((salesManager) => (
                <option key={salesManager.id} value={salesManager.id}>
                  {salesManager.full_name}
                </option>
              ))}
            </select>
          </div>
                

          {/* add button */}
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px]"
            type="submit"
          >
            {client.id ? "Update" : "Submit"}
          </Button>
        </form>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
