import { Card, CardActionArea } from "@mui/material";
import { helpers } from "../../helpers";
import StatusLabel from "../../components/StatusLabel";

type Props = {
  order: any;
  onTap?: (order: any) => void;
};
export default function OrderCard({ order, onTap }: Props) {
  return (
    <Card
      sx={{ maxWidth: "100%" }}
      className="!rounded-0 !shadow-sm !border !border-gray-100"
      onClick={() => onTap && onTap(order)}
    >
      <CardActionArea>
        <div className="flex flex-col w-full gap-y-1 bg-neutral-50 p-3">
          <div className="font-bold text-sm">{order.client?.full_name}</div>
          <div className="font-normal text-sm text-gray-500">ID: #{order.doc_number}</div>
          <div className="font-normal text-sm text-gray-500">Date: {order.created_at.toDate().toLocaleDateString()}</div>
          <div className="font-normal text-sm text-gray-500">Status: <StatusLabel status={order.status} className="ml-2" /></div>
          <div className="font-normal text-sm text-gray-500">Total items: <StatusLabel status={order.items?.length} className="ml-2" /></div>
          <div className="font-bold text-lg text-gray-500 flex justify-between gap-x-4"><span>Total:</span> <span className="text-black">N{helpers.money(order.total)}</span></div>
        </div>
      </CardActionArea>
    </Card>
  );
}
