/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import OrdersTable from "../components/OrdersTable";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";
import CircularLoader from "../components/CircularLoader";
import AppContext from "../AppContext";


const Home = () => {
    useDocumentTitle("Home");

    const navigate = useNavigate();

    const context: any = useContext(AppContext);

    const [stats, setStats] = useState({} as any);
    const fetchStats = async () => {
        let stats: any = {};
        //fetch stats from firebase
        const productsRef = collection(db, 'products');
        const usersRef = collection(db, 'users');
        const ordersRef = collection(db, 'orders');
        const paymentsRef = collection(db, 'payments');

        await getDocs(productsRef).then((snapshot) => {
            stats.products = snapshot.size;
        });
        await getDocs(usersRef).then((snapshot) => {
            stats.users = snapshot.size;
        });
        await getDocs(ordersRef).then((snapshot) => {
            stats.orders = snapshot.size;
        });
        await getDocs(paymentsRef).then((snapshot) => {
            stats.payments = snapshot.size;
        });

        setStats(stats);
    }

    const [orders, setOrders] = useState([] as any[]);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const fetchOrders = async () => {
        setOrdersLoading(true);
        let orders: any[] = [];
        //fetch orders from firebase
        const ordersRef = collection(db, 'orders');
        const q = query(ordersRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                orders.push({ ...doc.data(), id: doc.id });
            });
        });
        setOrders(orders);
        setOrdersLoading(false);
    }



    useEffect(() => {
        fetchStats();
        fetchOrders();
    }, []);



    const onTableUpdated = (order: any) => {
        fetchOrders();
    }



    return (
        <>
            <MainTemplate title="">


        
                <div className="grid gap-6 mb-8 grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 content-stretch">
                    {/* <!-- Products --> */}
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => {
                        navigate('/admin/products');
                    }}
                    >
                        <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-home-eco" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h5" />
                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.325 0 .631 .077 .902 .215" />
                                <path d="M16 22s0 -2 3 -4" />
                                <path d="M19 21a3 3 0 0 1 0 -6h3v3a3 3 0 0 1 -3 3z" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Products
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                {stats.products ?? (<CircularLoader />)}
                            </p>
                        </div>
                    </div>
                    {/* <!-- Users --> */}
                    {
                        [ 'admin', 'super_admin' ].includes(context.user.role) && (
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => {
                        if ([ 'admin', 'super_admin' ].includes(context.user.role)) {
                            navigate('/admin/users');
                        }
                    }}
                    >
                        <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-user-dollar" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="currentColor" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h3" />
                                <path d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                                <path d="M19 21v1m0 -8v1" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Users
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                { stats.users ?? (<CircularLoader />) }
                            </p>
                        </div>
                    </div>
                        )
                    }
                    {/* <!-- Orders --> */}
                    {
                        ([ 'admin', 'super_admin', 'accounting_manager', 'production_manager' ].includes(context.user.role)) && (
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => {
                        if ([ 'admin', 'super_admin', 'accounting_manager', 'production_manager' ].includes(context.user.role)) {
                            navigate('/admin/orders');
                        }
                    }}
                    >
                        <div className="p-3 mr-4 text-black bg-blue-100 rounded-full dark:text-blue-100 dark:bg-black">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-shopping-cart" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#597e8d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                <path d="M17 17h-11v-14h-2" />
                                <path d="M6 5l14 1l-1 7h-13" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Orders
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                {stats.orders ?? (<CircularLoader />)}
                            </p>
                        </div>
                    </div>
                        )
                    }
                    {/* <!-- Invoices --> */}
                    {
                        ([ 'admin', 'super_admin', 'accounting_manager' ].includes(context.user.role)) && (
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => {
                        if ([ 'admin', 'super_admin', 'accounting_manager' ].includes(context.user.role)) {
                            navigate('/admin/payments');
                        }
                    }}
                    >
                        <div className="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon w-5 h-5 icon-tabler icon-tabler-receipt" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2" />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Invoices
                            </p>
                            <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                                {stats.payments ?? (<CircularLoader />)}
                            </p>
                        </div>
                    </div>
                        )
                    }
                </div>


               

                {
                    ([ 'admin', 'super_admin', 'accounting_manager', 'production_manager' ].includes(context.user.role)) && (
                        <>
                            <OrdersTable orders={orders} loading={ordersLoading} onTableUpdated={onTableUpdated} headingTitle="Recent Orders" showViewAll={true} className="!mt-0" />
                        </>
                    )
                }






            </MainTemplate>
        </>
    );
};

export default Home;
