import { useEffect, useState } from "react";
import { DocumentData, OrderByDirection, collection, doc, getDoc, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "../..";

type UseFireDocProps = {
    docId: string;
    initialize?: boolean;
};
const useCategory = ({docId, initialize = false }: UseFireDocProps) => {
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState({} as any);

    useEffect(() => {
        if (initialize) {
            runQuery({});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const runQuery = async ({newDocId = null}) => {
        setLoading(true);
        const docRef = doc(db, "categories", (newDocId || docId)!);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setCategory({ ...docSnap.data(), id: docSnap.id });
        } else {
            console.log("No such category!");
        }
        setLoading(false);
    };

    return {
        loading,
        category,
        setCategory,
        runQuery,
    };

}

type UseFireDocsProps = {
    initialize?: boolean;
    addLimit?: number;
    addOrderBy?: string,
    addOrderDirection?: OrderByDirection,
};
const useCategories = ({ addOrderBy = "created_at", addOrderDirection = "asc", addLimit = 20, initialize = false, }: UseFireDocsProps) => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([] as any);

    useEffect(() => {
        if (initialize) {
            runQuery({});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    type RunQueryProps = {
        newAddOrderBy?: string,
        newAddOrderDirection?: OrderByDirection,
        newAddLimit?: number,
    };
    const runQuery = async ({newAddOrderBy, newAddOrderDirection, newAddLimit}: RunQueryProps) => {
        setLoading(true);
        const docRef = collection(db, "categories");
        const q = query(docRef, orderBy(newAddOrderBy || addOrderBy, newAddOrderDirection || addOrderDirection), limit(newAddLimit || addLimit));
        const docsSnap = await getDocs(q);
        const docs: DocumentData[] = [];
        docsSnap.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id });
        });
        setCategories(docs);
        setLoading(false);
    };

    return {
        loading,
        categories,
        setCategories,
        runQuery,
    };

}

export { useCategory, useCategories };