/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import AmenitiesTable from "../components/AmenitiesTable";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "..";



const Amenities = () => {
    useDocumentTitle('Amenities');

    const [amenities, setAmenities] = useState([] as any[]);
    const [amenitiesLoading, setAmenitiesLoading] = useState(false);
    const fetchAmenities =  async () => {
        setAmenitiesLoading(true);
        let amenities: any[] = [];
        //fetch orders from firebase, order by date created
        const amenitiesRef = collection(db, 'amenities');
        const q = query(amenitiesRef, orderBy('created_at', 'desc'));
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                amenities.push({ ...doc.data(), id: doc.id });
            });
        });
        setAmenities(amenities);
        setAmenitiesLoading(false);
    };


    useEffect(() => {
        fetchAmenities();
    }, []);

    const onTableUpdated = (amenity: any) => {
        fetchAmenities();
    }


    return (
        <>

            <MainTemplate title="Amenities">


                {/* <!-- Amenities --> */}
                <AmenitiesTable amenities={amenities} onTableUpdated={onTableUpdated} loading={amenitiesLoading} />

                <br />
                <br />
                <br />
                <br />



            </MainTemplate>

        </>
    );
}

export default Amenities;