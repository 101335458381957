/* eslint-disable eqeqeq */

import { toast } from "react-toastify";
import { db, firebaseConfig } from ".";
import axios from "axios";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const helpers = {
  codeMessage: function (data: any) {
    if (data.code == "validation-error") {
      data.messages.forEach((message: any) => {
        toast(message);
      });
    } else {
      toast(data.message);
    }
  },
  nFormatter: function (num: any, digits: any) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  },
  dynamicColors: function (opacity = 1) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  },
  capitalizeFirstLetter: function (string: string) {
    return !string || string.length == 0
      ? ""
      : string.charAt(0).toUpperCase() + string.slice(1);
  },
  capitalize: function (string: string) {
    if (!string) {
      return "";
    }
    if (typeof string !== "string") {
      return "";
    }
    if (string.length == 0) {
      return "";
    }
    return string.replace(/^[a-z]/, (match) => match.toUpperCase());
  },
  capitalizeEachWord: function (string: string) {
    return !string || string.length == 0
      ? ""
      : string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  },
  money: function (price: any) {
    if (price == null || price == "" || isNaN(price) || price == undefined) {
      return "0.00";
    }
    let formatter = Intl.NumberFormat("en-US");
    return formatter.format(Number(price));
  },
  statusColor: function (status: string) {
    var color = "black";
    switch (status) {
      case "active":
      case "paid":
      case "completed":
        color = "#689142";
        break;
      case "inactive":
        color = "#7F7F7F";
        break;
      case "suspended":
        color = "#FF2200";
        break;
      case "pending":
        color = "#D6C410";
        break;
      case "canceled":
      case "cancelled":
        color = "#EC050C";
        break;
      case "processing":
        color = "#E3B7EB";
        break;
      case "accepted":
        color = "#74B801";
        break;
      default:
        break;
    }
    return color;
  },
  geoDistance: function (lat1: number, lon1: number, lat2: number, lon2: number) {
    function toRad(val: number) {
      return (val * Math.PI) / 180;
    }

    var R = 6371; // Radius of the Earth in km

    var dLat = toRad(lat2 - lat1); // Javascript functions in radians
    var dLon = toRad(lon2 - lon1);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  },
  defaultThumbnail: function (type = "", name = "") {
    if (type == "avatar" && name != "") {
      return `https://ui-avatars.com/api/?name=${name}&background=random&color=ffffff&size=150`;
    }
    return "https://via.placeholder.com/150";
  },
  generateSearchKeywords: function (title: string) {
    var keywords: any = [];
    var words = title.toLowerCase().split(" ");
    words.forEach((word) => {
      var temp = "";
      for (let i = 0; i < word.length; i++) {
        temp += word[i];
        keywords.push(temp);
      }
    });
    return keywords;
  },
  generateLiteSearchKeywords: function (title: string) {
    var keywords: any = [];
    var words = title.toLowerCase().split(" ");
    words.forEach((word) => {
      if (word.length > 0) {
        keywords.push(word);
      }
    });
    return keywords;
  },
  getSlug: function (title: string) {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  },
  getFirstImage: function (image: any, type = "", name = "") {
    if (image == null || image == "") {
      return this.defaultThumbnail(type, name);
    } else if (Array.isArray(image)) {
      const img = image[0];
      //if img is an object
      if (typeof img === "object" && !Array.isArray(img)) {
        return img.url ?? img.image ?? this.defaultThumbnail(type, name);
      }
      return img ?? this.defaultThumbnail(type, name);
    }

    return image;
  },
  generateRandomString: function (length = 10) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  },
  pushNotificationToToken: async function (token: any, notification: any, data = null) {
    const fcm_url = "https://fcm.googleapis.com/fcm/send";
    const fcm_key = firebaseConfig.fcmServerKey;
    const body = {
      to: token,
      notification: notification,
      data: data ?? {},
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "key=" + fcm_key,
    };

    await axios
      .post(fcm_url, body, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  pushNotificationToTopic: async function (topic: any, notification: any, data: any = null) {
    const fcm_url = "https://fcm.googleapis.com/fcm/send";
    const fcm_key = firebaseConfig.fcmServerKey;
    const body = {
      to: "/topics/" + topic,
      notification: notification,
      data: data ?? {},
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: "key=" + fcm_key,
    };

    await axios
      .post(fcm_url, body, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getLastDocumentNumber: async function (collection: any) {
    const ref = doc(db, "counters", collection);
    const document = await getDoc(ref);
    if (document.exists()) {
      const data = document.data();
      return data["last_document_number"] || 0;
    } else {
      return 0;
    }
  },
  getNextDocumentNumber: async function (collection: any, updateNext = true) {
    const id = await this.getLastDocumentNumber(collection);
    const next = id + 1;

    if (updateNext) {
      const ref = doc(db, "counters", collection);
      await updateDoc(ref, { last_document_number: next });
    }

    return next;
  },
};
