import { useEffect, useState } from "react";
import {
  DocumentData,
  OrderByDirection,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../..";
import { helpers } from "../../helpers";

type UseFireDocProps = {
  docId: string;
  initialize?: boolean;
};
const useProduct = ({ docId, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runQuery = async ({ newDocId = null }) => {
    setLoading(true);
    const docRef = doc(db, "products", (newDocId || docId)!);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProduct({ ...docSnap.data(), id: docSnap.id });
    } else {
      console.log("No such product!");
    }
    setLoading(false);
  };

  return {
    loading,
    product,
    setProduct,
    runQuery,
  };
};

type UseFireDocsProps = {
  initialize?: boolean;
  addLimit?: number;
  addOrderBy?: string;
  addOrderDirection?: OrderByDirection;
  search?: string;
  ids?: string[];
  minPrice?: number;
  maxPrice?: number;
  addCategories?: string[]; //category ids
  paginate?: boolean;
  addPage?: number;
};
const useProducts = ({
  addOrderBy = "created_at",
  addOrderDirection = "desc",
  addLimit = 20,
  initialize = false,
  search = "",
  ids = [],
  minPrice = 0,
  maxPrice = 0,
  addCategories = [],
  addPage = 0,
  paginate = false,
}: UseFireDocsProps) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([] as any);
  const [lastDocs, setLastDocs] = useState([] as any);
  const [page, setPage] = useState(addPage);
  const [currentLimit, setCurrentLimit] = useState(addLimit);
  const [categories, setCategories] = useState(addCategories);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type RunProductsQueryProps = {
    newAddOrderBy?: string;
    newAddOrderDirection?: OrderByDirection;
    newAddLimit?: number;
    newPage?: number;
    newPaginate?: boolean;
    newSearch?: string;
    newCategories?: string[];
  };
  const runQuery = async ({
    newAddOrderBy,
    newAddOrderDirection,
    newAddLimit,
    newPage,
    newPaginate,
    newSearch,
    newCategories,
  }: RunProductsQueryProps) => {
    if (newPage !== undefined && newPage !== null) {
      setPage(newPage);
    }
    if (newAddLimit) {
      setCurrentLimit(newAddLimit);
    }
    if (newCategories) {
      setCategories(newCategories);
    }

    setLoading(true);
    const docRef = collection(db, "products");
    var queries: any[] = [where("status", "==", "publish")];

    //search in search_keywords
    if ((newSearch || search).length > 0) {
      var searchList = helpers.generateLiteSearchKeywords(
        (newSearch || search).toLowerCase()
      );
      queries.push(where("search_keywords", "array-contains-any", searchList));
    }

    //categories
    if ((newCategories || categories).length > 0) {
      queries.push(
        where(
          "categories_ids",
          "array-contains-any",
          newCategories || categories
        )
      );
    }

    //min price
    if (minPrice > 0) {
      queries.push(where("price", ">=", minPrice));
    }

    //max price
    if (maxPrice > 0) {
      queries.push(where("price", "<=", maxPrice));
    }

    //ids
    if (ids.length > 0) {
      //make sure orderBy query is removed above
      queries.push(where("doc_number", "in", ids));
    }

    //orderBy
    queries.push(
      orderBy(
        newAddOrderBy || addOrderBy,
        newAddOrderDirection || addOrderDirection
      )
    );

    // if page index exists, get last doc from lastDocs
    if (lastDocs[(newPage || page) - 1] && (newPaginate || paginate)) {
      queries.push(startAfter(lastDocs[(newPage || page) - 1]));
    }

    //limit
    if (currentLimit > 0) {
      queries.push(limit(newAddLimit || currentLimit));
    }

    const q = query(docRef, ...queries);
    const docsSnap = await getDocs(q);

    var lastDoc = docsSnap.docs[docsSnap.docs.length - 1];
    //check if lastDoc already exists in lastDocs
    if (!lastDocs.includes(lastDoc) && lastDoc) {
      //check if lastDoc.id is not in any of the lastDocs
      if (!lastDocs.some((doc: any) => doc.id === lastDoc.id)) {
        var lists: any[] = lastDocs;
        lists.push(lastDoc);
        setLastDocs(lists);
      }
    }

    const docs: DocumentData[] = [];
    docsSnap.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    setProducts(docs);
    setLoading(false);
  };

  const resetPagination = () => {
    setPage(0);
    setLastDocs([]);
  };

  const nextPage = () => {
    runQuery({ newPage: page + 1, newPaginate: true });
  };

  return {
    loading,
    products,
    setProducts,
    runQuery,
    resetPagination,
    nextPage,
    page,
    currentLimit,
    categories,
  };
};

export { useProduct, useProducts };
