import { useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";


type Props = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    user?: any;
    setUser: (user: any) => void;
    onModalUpdated: (user: any) => void;
}
const UserModal = ({ showModal, setShowModal, user, setUser, onModalUpdated }: Props) => {

    const [userData, setUserData] = useState(user ?? {});

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const onInputChanged = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }


    const generatePassword = () => {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const passwordLength = 8;
        var password = "";

        for (var i = 0; i <= passwordLength; i++) {
            const randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }

        setUserData({ ...userData, password: password });
    }

    const onFormSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Please wait...");

        let formData: any = { ...userData };

        //if status is approved, then set to active
        if (formData.status === "approved") {
            formData.status = "active";
        } else {
            formData.status = formData.status ?? "pending";
        }

        //if email, set it to lowercase
        if (formData.email && formData.email !== "") {
            formData.email = formData?.email?.toLowerCase();
        }

        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.full_name);

            //add to firebase
            const userRef = doc(db, "users", userData.id);
            await updateDoc(userRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setUser({ ...docSnap.data(), id: docSnap.id });
                    setUserData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Updated successfully");
                } else {
                    toast.error("Failed to update");
                }
            }).catch((error) => {
                toast.error("Failed to update");
            });



        } else { //add
            formData.doc_number = await helpers.getNextDocumentNumber("users");
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.full_name);

            const usersRef = collection(db, "users");

            //check if email exists
            const emailQuerySnapshot = await getDocs(query(usersRef, where("email", "==", formData.email)));
            if (!emailQuerySnapshot.empty) {
                toast.error("Email already exists");
                toast.dismiss(loading);
                return;
            }

            //check if phone exists
            const phoneQuerySnapshot = await getDocs(query(usersRef, where("phone", "==", formData.phone)));
            if (!phoneQuerySnapshot.empty) {
                toast.error("Phone already exists");
                toast.dismiss(loading);
                return;
            }


            await addDoc(usersRef, formData).then(async (docRef) => {
                //get the added data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUser({ ...docSnap.data(), id: docSnap.id });
                    setUserData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Added successfully");
                } else {
                    toast.error("Failed to add");
                }
            }).catch((error) => {
                toast.error("Failed to add");
            });
        }

        toast.dismiss(loading);
        setShowModal(false);
        onModalUpdated(formData);
    }



    return (
        <>
            <SkyeModal title={userData.id ? "User Information" : "New User"} size="max-w-7xl" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form className="space-y-6" id="user-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data" autoComplete="off" autoSave="off">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">

                        {/* body */}

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="full_name" className="block text-sm font-medium text-gray-700">
                                    Full name
                                </label>
                                <input
                                    name='full_name'
                                    value={userData.full_name || ""}
                                    onChange={onInputChanged}
                                    type="text" id="full_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Full name" />
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <input
                                    name='email'
                                    value={userData.email || ""}
                                    onChange={onInputChanged}
                                    required
                                    type="email" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email address" />

                            </div>
                            {/* city */}
                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <input
                                    name='city'
                                    value={userData.city || ""}
                                    onChange={onInputChanged}
                                    type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="City" />
                            </div>
                            {/* state */}
                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                    State
                                </label>
                                <input
                                    name='state'
                                    value={userData.state || ""}
                                    onChange={onInputChanged}
                                    type="text" id="state" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="State" />
                            </div>
                            {/* country */}
                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <input
                                    name='country'
                                    value={userData.country || ""}
                                    onChange={onInputChanged}
                                    type="text" id="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Country" />
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    Address
                                </label>
                                <input
                                    name='address'
                                    value={userData.address || ""}
                                    onChange={onInputChanged}
                                    type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Address" />
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                    Phone
                                </label>
                                <input
                                    name='phone'
                                    value={userData.phone || ""}
                                    onChange={onInputChanged}
                                    required
                                    type="text" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Phone" />
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700">
                                    Zip
                                </label>
                                <input
                                    name='zip_code'
                                    value={userData.zip_code || ""}
                                    onChange={onInputChanged}
                                    type="text" id="zip_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Zip Code" />
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                                    Gender
                                </label>
                                <select
                                    name="gender"
                                    value={userData.gender || ""}
                                    onChange={onInputChanged}
                                    id="gender" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="None">None</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                    Date of Birth
                                </label>
                                <input
                                    name='dob'
                                    value={userData.dob || ""}
                                    onChange={onInputChanged}
                                    type="date" id="dob" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                            {
                                (!userData.id && (
                                    <div className="col-span-6 grid grid-cols-6 gap-6">
                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Password
                                            </label>
                                            <div className="grid grid-cols-4 gap-x-2">
                                                <input
                                                    name='password'
                                                    value={userData.password || ""}
                                                    onChange={onInputChanged}
                                                    required
                                                    type="text" id="password" className="col-span-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Password" />
                                                <div className="flex items-center">
                                                    <button type="button" onClick={generatePassword} className="py-1 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200">Generate</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }


                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                    Role
                                </label>
                                <select
                                    name='role'
                                    value={userData.role || ""}
                                    onChange={onInputChanged}
                                    required
                                    id="role" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="">Select a role</option>
                                    <option value="sales_manager">Sales Manager</option> {/* seller */}
                                    <option value="inventory_manager">Inventory Manager</option> {/* warehouse */}
                                    <option value="production_manager">Production Manager</option> {/* production management */}
                                    <option value="accounting_manager">Accounting Manager</option> {/* accounting */}
                                    <option value="customers_manager">Customers Manager</option> {/* customers management */}
                                    {/* <option value="delivery_manager">Delivery Manager</option> */} {/* delivery */}
                                    {/* <option value="client">Client</option> client  */} {/* clients are saved into another table */}
                                    <option value="admin">Admin</option>
                                </select>
                            </div>

                            <div className="col-span-6 lg:col-span-3">
                                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                                    Status
                                </label>
                                <select
                                    name='status'
                                    value={userData.status || ""}
                                    onChange={onInputChanged}
                                    id="status" className="bg-gray-50 border-2 border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="pending">Pending</option>
                                    <option value="approved">Approved</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>

                        </div>


                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">

                        <button type="submit" className="bg-black hover:bg-blue-600 text-white font-semibold py-2 px-20 text-xl rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            Save
                        </button>

                    </div>
                </form>
            </SkyeModal >
        </>
    );
}

export default UserModal;