/* eslint-disable eqeqeq */
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos } from "@mui/icons-material";
import { Shimmer } from "react-shimmer";
import CheckoutItemCard from "../components/CheckoutItemCard";
import { helpers } from "../../helpers";
import { useOrder } from "../hooks/useOrders";
import { useContext } from "react";
import AppContext from "../../AppContext";
import StatusLabel from "../../components/StatusLabel";

export default function AppOrder() {
  const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const { id } = useParams();
  const { order, loading } = useOrder({ docId: id!, initialize: true });

  return (
    <>
      {/* header */}
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* back button */}
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos />
              </IconButton>
            </li>
          </ul>
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full">
            Order Details
          </p>
          <div className="w-12"></div>
        </div>
      </header>
      <div className="mt-[80px]"></div>

      <div className="sm:px-80">
      {loading ? (
        <ShimmerPage />
      ) : (
        <div className="px-4">
          {/* empty cart placeholder */}
          {!(order.id && order.sales_manager_id == context.user.id) ? (
            <div className="flex flex-col items-center justify-center gap-y-2 mt-[150px] mb-[100px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[80px] h-[80px] text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <div className="text-2xl text-gray-400 font-normal">
                Order not found
              </div>
            </div>
          ) : (
            <>
              {/* order info */}
              <div className="border border-gray-300 p-3 flex flex-col gap-y-2">
                <div className="flex flex-row items-center justify-between gap-x-10">
                  <div className="font-normal text-gray-600">ID</div>
                  <div className="font-bold">#{order.doc_number}</div>
                </div>
                <div className="flex flex-row items-center justify-between gap-x-10">
                  <div className="font-normal text-gray-600">Status</div>
                  <div className="font-bold"><StatusLabel status={order.status} /></div>
                </div>
                <div className="flex flex-row items-center justify-between gap-x-10">
                  <div className="font-normal text-gray-600">Date</div>
                  <div className="font-bold">{order.created_at.toDate().toLocaleDateString()}</div>
                </div>
                <div className="flex flex-row items-center justify-between gap-x-10">
                  <div className="font-normal text-gray-600">Name</div>
                  <div className="font-bold">{order.client?.full_name}</div>
                </div>
                <div className="flex flex-row items-center justify-between gap-x-10">
                  <div className="font-normal text-gray-600">Phone</div>
                  <div className="font-bold">{order.client?.phone}</div>
                </div>
                <div className="flex flex-row items-center justify-between gap-x-10">
                  <div className="font-normal text-gray-600">Address</div>
                  <div className="font-bold">
                    {order.client?.address} {order.client?.state}
                  </div>
                </div>
              </div>
              {/* order items */}
              {order.items?.length > 0 && (
                <div className="my-4 space-y-2">
                  {order.items.map((item: any) => {
                    return <CheckoutItemCard key={item.id} item={item} showAttributes={true} />;
                  })}
                </div>
              )}
              {/* total */}
              <div className="flex flex-row items-center justify-between gap-x-2 my-2 border border-gray-300 p-3">
                <div className="text-xl font-medium leading-tight w-full">
                  Total
                </div>
                <div className="text-2xl font-bold py-0.5 px-3">
                  N{helpers.money(order.total)}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

const ShimmerPage = () => {
  return (
    <>
      {/* page */}
      <div className="px-4 space-y-4">
        {/* cart items */}
        <div className="space-y-2">
          {[1, 2, 3, 4].map((item: any) => {
            return (
              <Shimmer
                key={item}
                width={130}
                height={120}
                className="rounded-lg !w-full"
              />
            );
          })}
        </div>
        {/* cart total */}
        <Shimmer width={130} height={50} className="rounded-lg !w-full" />
        {/* checkout button */}
        <Shimmer
          width={130}
          height={50}
          className="rounded-lg !w-full !h-[60px]"
        />
      </div>
    </>
  );
};
