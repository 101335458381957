import { useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import { toast } from "react-toastify";
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { helpers } from "../../helpers";
import { db } from "../..";

type Props = {
    title?: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    category?: any;
    setCategory: (category: any) => void;
    onModalUpdated: (category: any) => void;
}
const CategoryModal = ({ title = "New Category", showModal, setShowModal, category, setCategory, onModalUpdated }: Props) => {

    const [categoryData, setCategoryData] = useState(category ?? {});

    useEffect(() => {
        setCategoryData(category);
    }, [category]);

    const onInputChanged = (e: any) => {
        setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = async (e: any) => {
        e.preventDefault();
        let formData: any = { ...categoryData };

        const loading = toast.loading("Please wait...");

        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            delete formData.slug;
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);

            //add to firebase
            const categoryRef = doc(db, "categories", categoryData.id);
            await updateDoc(categoryRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(categoryRef);
                if (docSnap.exists()) {
                    setCategory({ ...docSnap.data(), id: docSnap.id });
                    setCategoryData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Updated successfully");
                } else {
                    toast.error("Failed to update");
                }
            }).catch((error) => {
                toast.error("Failed to update");
            }
            );

        } else { //add
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.name);
            formData.slug = helpers.getSlug(formData.name);

            const categoriesRef = collection(db, "categories");

            //add timestamp to slug if slug already exists
            const slugQuery = query(categoriesRef, where("slug", "==", formData.slug));
            const slugQuerySnapshot = await getDocs(slugQuery);
            if (slugQuerySnapshot.size > 0) {
                formData.slug += `-${formData.created_at.seconds}`;
            }

            //add to firebase
            await addDoc(categoriesRef, formData).then(async (docRef) => {
                //get the added data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setCategory({ ...docSnap.data(), id: docSnap.id });
                    setCategoryData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Added successfully");
                } else {
                    toast.error("Failed to add");
                }
            }).catch((error) => {
                toast.error("Failed to add");
            });
        }

        toast.dismiss(loading);
        setShowModal(false);
    }

        return (
            <>
                <SkyeModal title={categoryData.id ? "Category" : "New Category"} size="max-w-sm" flex={false} showModal={showModal} setShowModal={setShowModal}>
                    <form autoComplete="off" className="space-y-6" id="category-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                        {/*body*/}
                        <div className="relative px-6 pt-6 pb-2 flex-auto">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                                <input
                                    name="name"
                                    value={categoryData.name || ""}
                                    onChange={onInputChanged}
                                    type="text" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="Name" required />
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button type="submit" className="w-full text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-gray-700 dark:focus:ring-black">
                                <span id="submit-text">{categoryData.id ? "Update" : "Add"}</span>
                            </button>
                        </div>
                    </form>
                </SkyeModal>
            </>
        );
    }

    export default CategoryModal;