import { useContext, useEffect, useState } from "react";
import {
  DocumentData,
  OrderByDirection,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../..";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";

type UseFireDocProps = {
  docId: string;
  initialize?: boolean;
};
const usePayment = ({ docId, initialize = false }: UseFireDocProps) => {
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState({} as any);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type RunQueryProps = {
    newDocId?: string;
  };
  const runQuery = async ({ newDocId }: RunQueryProps) => {
    setLoading(true);
    setPayment(null);
    const docRef = doc(db, "payments", (newDocId || docId)!);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setPayment({ ...docSnap.data(), id: docSnap.id });
    } else {
      setPayment(null);
      console.log("No such payment!");
    }
    setLoading(false);
  };

  return {
    loading,
    payment,
    setPayment,
    runQuery,
  };
};

type UseFireDocsProps = {
  initialize?: boolean;
  addLimit?: number;
  addOrderBy?: string;
  addOrderDirection?: OrderByDirection;
  search?: string;
  status?: string;
  ids?: any[];
  minPrice?: number;
  maxPrice?: number;
  paginate?: boolean;
  addPage?: number;
  orderedUser?: string;
  dateFrom?: string;
  dateTo?: string;
};
const usePayments = ({
  addOrderBy = "created_at",
  addOrderDirection = "desc",
  addLimit = 0,
  initialize = false,
  search = "",
  status = "",
  ids = [],
  minPrice = 0,
  maxPrice = 0,
  addPage = 0,
  paginate = false,
  orderedUser = "",
  dateFrom = "",
  dateTo = "",
}: UseFireDocsProps) => {
  const context: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([] as any);
  const [lastDocs, setLastDocs] = useState([] as any);
  const [page, setPage] = useState(addPage);
  const [currentLimit, setCurrentLimit] = useState(addLimit);

  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [totalProcessing, setTotalProcessing] = useState(0);
  const [totalProcessingAmount, setTotalProcessingAmount] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);
  const [totalCancelledAmount, setTotalCancelledAmount] = useState(0);
  const [totalRefunded, setTotalRefunded] = useState(0);
  const [totalRefundedAmount, setTotalRefundedAmount] = useState(0);

  useEffect(() => {
    if (initialize) {
      runQuery({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type RunQueryProps = {
    newAddOrderBy?: string;
    newAddOrderDirection?: OrderByDirection;
    newAddLimit?: number;
    newPage?: number;
    newPaginate?: boolean;
    newSearch?: string;
    newIds?: any[];
    newFromDate?: any;
    newToDate?: any;
  };
  const runQuery = async ({
    newAddOrderBy,
    newAddOrderDirection,
    newAddLimit,
    newPage,
    newPaginate,
    newSearch,
    newIds,
    newFromDate,
    newToDate,
  }: RunQueryProps) => {

    if (newPage !== undefined && newPage !== null) {
      setPage(newPage);
    }
    if (newAddLimit) {
      setCurrentLimit(newAddLimit);
    }

    setLoading(true);
    const docRef = collection(db, "payments");
    var queries: any[] = [];

    if (
      ![
        "admin",
        "super_admin",
        "production_manager",
        "accounting_manager",
      ].includes(context.user?.role)
    ) {
      queries.push(where("sales_manager_id", "==", context.user?.id));
    }

    //status
    if (status.length > 0) {
      queries.push(where("status", "==", status));
    }

    //search in search_keywords
    if ((newSearch || search).length > 0) {
      var searchList = helpers.generateLiteSearchKeywords(
        (newSearch || search).toLowerCase()
      );
      queries.push(where("search_keywords", "array-contains-any", searchList));
    }

    //orderedUser
    if (orderedUser.length > 0) {
      queries.push(where("user_id", "==", orderedUser));
    }

    //dateFrom
    if (newFromDate || (dateFrom.length > 0)) {
      queries.push(where("created_at", ">=", newFromDate || dateFrom));
    }

    //dateTo
    if (newToDate || (dateTo.length > 0)) {
      queries.push(where("created_at", "<=", newToDate || dateTo));
    }

    //min price
    if (minPrice > 0) {
      queries.push(where("total", ">=", minPrice));
    }

    //max price
    if (maxPrice > 0) {
      queries.push(where("total", "<=", maxPrice));
    }

    //ids
    if ((newIds || ids).length > 0) {
      //make sure orderBy query is removed above
      queries.push(where('doc_number', "in", newIds || ids));
    }

    //orderBy
    queries.push(
      orderBy(
        newAddOrderBy || addOrderBy,
        newAddOrderDirection || addOrderDirection
      )
    );

    // if page index exists, get last doc from lastDocs
    if (lastDocs[(newPage || page) - 1] && (newPaginate || paginate)) {
      queries.push(startAfter(lastDocs[(newPage || page) - 1]));
    }

    if (currentLimit > 0) {
      queries.push(limit(newAddLimit || currentLimit));
    }

    const q = query(docRef, ...queries);
    const docsSnap = await getDocs(q);

    var lastDoc = docsSnap.docs[docsSnap.docs.length - 1];
    //check if lastDoc already exists in lastDocs
    if (!lastDocs.includes(lastDoc) && lastDoc) {
      //check if lastDoc.id is not in any of the lastDocs
      if (!lastDocs.some((doc: any) => doc.id === lastDoc.id)) {
        var lists: any[] = lastDocs;
        lists.push(lastDoc);
        setLastDocs(lists);
      }
    }

    //clear totals
    var tSales = 0;
    var tSalesAmount = 0;
    var tPending = 0;
    var tPendingAmount = 0;
    var tPaid = 0;
    var tPaidAmount = 0;
    var tProcessing = 0;
    var tProcessingAmount = 0;
    var tCancelled = 0;
    var tCancelledAmount = 0;
    var tRefunded = 0;
    var tRefundedAmount = 0;

    const docs: DocumentData[] = [];
    docsSnap.forEach((doc) => {
      const data: any = { ...doc.data(), id: doc.id };
      docs.push(data);

      //for sales manager
      if (data.status === "paid") {
        tPaid++;
        tPaidAmount = tPaidAmount + parseFloat(data.amount);
      } else if (data.status === "pending") {
        tPending++;
        tPendingAmount = tPendingAmount + parseFloat(data.amount);
      } else if (data.status === "processing") {
        tProcessing++;
        tProcessingAmount = tProcessingAmount + parseFloat(data.amount);
      } else if (data.status === "cancelled" || data.status === "canceled") {
        tCancelled++;
        tCancelledAmount = tCancelledAmount + parseFloat(data.amount);
      } else if (data.status === "refunded") {
        tRefunded++;
        tRefundedAmount = tRefundedAmount + parseFloat(data.amount);
      }

      tSales++;
      tSalesAmount = tSalesAmount + parseFloat(data.amount);
    });
    setPayments(docs);

    //for sales manager
    setTotalSales(tSales);
    setTotalSalesAmount(tSalesAmount);
    setTotalPending(tPending);
    setTotalPendingAmount(tPendingAmount);
    setTotalPaid(tPaid);
    setTotalPaidAmount(tPaidAmount);
    setTotalProcessing(tProcessing);
    setTotalProcessingAmount(tProcessingAmount);
    setTotalCancelled(tCancelled);
    setTotalCancelledAmount(tCancelledAmount);
    setTotalRefunded(tRefunded);
    setTotalRefundedAmount(tRefundedAmount);

    setLoading(false);
  };

  const resetPagination = () => {
    setPage(0);
    setLastDocs([]);
  };

  const nextPage = () => {
    runQuery({ newPage: page + 1, newPaginate: true });
  };

  return {
    loading,
    payments,
    setPayments,
    runQuery,
    resetPagination,
    nextPage,
    page,
    currentLimit,
    //for sales manager
    totalSales,
    totalSalesAmount,
    totalPending,
    totalPendingAmount,
    totalPaid,
    totalPaidAmount,
    totalProcessing,
    totalProcessingAmount,
    totalCancelled,
    totalCancelledAmount,
    totalRefunded,
    totalRefundedAmount,
  };
};

export { usePayment, usePayments };
