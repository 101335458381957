/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useContext } from "react";
import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";
import Loading from "../components/Loading";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "..";
import { ErrorHandlerComponent } from "../components/ErrorHandlerComponet";
import { toast } from "react-toastify";
import AppContext from "../AppContext";


const Profile = () => {
    useDocumentTitle('Profile');

    const context: any = useContext(AppContext);


    const [profile, setProfile] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null as any);

    const fetchProfile = async () => {
        try {
            const docRef = doc(db, "users", context.user.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setProfile(docSnap.data());
            } else {
                setError("Account not found!");
                toast.error("Account not found!");
            }
        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchProfile();
    }, []);

    const settingInputChange = (e: any) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    }


    const submitProfile = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Updating Profile...");

        try {
            const docRef = doc(db, "users", context.user.id);
            await updateDoc(docRef, profile);
            toast.success("Profile Updated!");
        }
        catch (error) {
            toast.error("Error Updating Profile!");
        }
        finally {
            toast.dismiss(loading);
        }
    }

    const [passwordData, setPasswordData] = useState({} as any);

    const passwordInputChange = (e: any) => {
        setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
    }

    const submitPassword = async (e: any) => {
        e.preventDefault();

        if (passwordData.password !== passwordData.password_confirmation) {
            toast.error("Password and Confirm Password does not match!");
            return;
        }

        const loading = toast.loading("Updating Password...");

        try {
            const docRef = doc(db, "users", context.user.id);

            //get current password
            const docSnap = await getDoc(docRef);
            const currentPassword = docSnap.data()?.password;

            if (currentPassword !== passwordData.current_password) {
                toast.error("Current Password is incorrect!");
                return;
            }


            await updateDoc(docRef, {password: passwordData.password});
            toast.success("Password Updated!");
        }
        catch (error) {
            toast.error("Error Updating Password!");
        }
        finally {
            toast.dismiss(loading);
        }

    }


    if (loading) {
        return (<Loading />);
    }

    if (error) {
        return (<ErrorHandlerComponent error={error} />);
    }


    return (
        <>

            <MainTemplate title="Profile">

                {/* PROFILE */}
                <div className="mt-10">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                        <div className="lg:col-span-1">
                            <div className="px-4 lg:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                                <p className="mt-1 text-sm text-gray-600">This is your basic profile info.</p>
                            </div>
                        </div>
                        <div className="mt-5 lg:col-span-2 lg:mt-0">
                            <form onSubmit={submitProfile} method="POST">
                                <div className="overflow-hidden shadow lg:rounded-md">
                                    <div className="bg-white px-4 py-5 lg:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 lg:col-span-3">
                                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                    First name
                                                </label>
                                                <input
                                                    name='first_name'
                                                    value={profile.first_name || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="John" />
                                            </div>

                                            <div className="col-span-6 lg:col-span-3">
                                                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                                    Last name
                                                </label>
                                                <input
                                                    name='last_name'
                                                    value={profile.last_name || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Doe" />

                                            </div>

                                            <div className="col-span-6">
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    Email address
                                                </label>
                                                <input
                                                    name='email'
                                                    defaultValue={profile.email || ""}
                                                    disabled
                                                    readOnly
                                                    type="email" className="bg-gray-50 border border-gray-300 text-gray-500 text-sm font-semibold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                                                />
                                            </div>
                                            {/* city */}
                                            <div className="col-span-6 lg:col-span-3">
                                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                                    City
                                                </label>
                                                <input
                                                    name='city'
                                                    value={profile.city || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="" />
                                            </div>
                                            {/* state */}
                                            <div className="col-span-6 lg:col-span-3">
                                                <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                                    State
                                                </label>
                                                <input
                                                    name='state'
                                                    value={profile.state || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="state" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="" />
                                            </div>
                                            {/* country */}
                                            <div className="col-span-6 lg:col-span-3">
                                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                    Country
                                                </label>
                                                <input
                                                    name='country'
                                                    value={profile.country || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="" />
                                            </div>

                                            <div className="col-span-6">
                                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                                    Address
                                                </label>
                                                <input
                                                    name='address'
                                                    value={profile.address || ""}
                                                    onChange={settingInputChange}
                                                    type="text" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Address" />
                                            </div>


                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right lg:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* CHANGE PASSWORD */}
                <div className="hidden lg:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

                <div className="mt-10 lg:mt-0">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                        <div className="lg:col-span-1">
                            <div className="px-4 lg:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Change Password</h3>
                                <p className="mt-1 text-sm text-gray-600">Changing password requires your current and new password.</p>
                            </div>
                        </div>
                        <div className="mt-5 lg:col-span-2 lg:mt-0">
                            <form onSubmit={submitPassword} method="POST">
                                <div className="overflow-hidden shadow lg:rounded-md">
                                    <div className="space-y-6 bg-white px-4 py-5 lg:p-6">
                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="current_password" className="block text-sm font-medium text-gray-700">
                                                Current Password
                                            </label>
                                            <input
                                                name='current_password'
                                                value={passwordData.current_password || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="current_password" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Password" required />
                                        </div>

                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                New Password
                                            </label>
                                            <input
                                                name='password'
                                                value={passwordData.password || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="password" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Password" required />

                                        </div>
                                        <div className="col-span-6 lg:col-span-3">
                                            <label htmlFor="password_confirmation" className="block text-sm font-medium text-gray-700">
                                                Confirm Password
                                            </label>
                                            <input
                                                name='password_confirmation'
                                                value={passwordData.password_confirmation || ""}
                                                onChange={passwordInputChange}
                                                type="password" id="password_confirmation" style={{ fontFamily: 'Verdana' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black" placeholder="Password" required />

                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right lg:px-6">
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </MainTemplate>

        </>
    );
}

export default Profile;