import { useContext, useEffect, useState } from "react";
import SkyeModal from "../../components/SkyeModal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db, storage } from "../../index";
import { toast } from "react-toastify";
import { helpers } from "../../helpers";
import AppContext from "../../AppContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

type Props = {
    showModal: boolean,
    setShowModal: any,
    post: any,
    setPost: any,
    onModalUpdated: any
}
const PostModal = ({ showModal, setShowModal, post, setPost, onModalUpdated }: Props) => {

    const context: any = useContext(AppContext);

    const [postData, setPostData] = useState(post ?? {});
    const [content, setContent] = useState(post.content || "");

    useEffect(() => {
        setPostData(post);
        setContent(postData.content ?? "");
    }, [post, postData.content]);

    const onInputChanged = (e: any) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    const [coverImageFile, setCoverImageFile] = useState(null as any);

    const onFileInputChanged = (e: any) => {
        setCoverImageFile(e.target.files[0]);
    }


    const clearImage = () => {
        setCoverImageFile(null);
        setPostData({ ...postData, image: null });
    }


    const onFormSubmit = async (e: any) => {
        e.preventDefault();

        const loading = toast.loading("Please wait...");

        let formData: any = { ...postData };

          //add image
          if (coverImageFile) {
            //upload image
            const imageName = formData.title + "-" + helpers.generateRandomString(10) + "." + coverImageFile.name.split(".").pop();
            const imageRef = ref(storage, "images/posts/" + imageName);
            await uploadBytes(imageRef, coverImageFile).then(async (snapshot) => {
                await getDownloadURL(imageRef).then((url) => {
                    formData.image = url;
                });
            }
            ).catch((error) => {
                toast.error("Failed to upload image");
            });
        }

        if (formData.id) { //update
            //remove id, created_at
            delete formData.id;
            delete formData.created_at;
            delete formData.slug;
            //update updated_at
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.title);
            //add content
            formData.content = content;

            if (formData.user_id) {
                //get user info
                const userRef = doc(db, "users", formData.user_id);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    formData.user = {...userSnap.data(), id: userSnap.id};
                }
            }

            //add to firebase
            const postRef = doc(db, "posts", postData.id);
            await updateDoc(postRef, formData, { merge: true }).then(async () => {
                //get the updated data
                const docSnap = await getDoc(postRef);
                if (docSnap.exists()) {
                    setPost({ ...docSnap.data(), id: docSnap.id });
                    setPostData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Updated successfully");
                }
            }).catch((error) => {
                toast.error("Failed to update");
            });

        } else { //add
            formData.doc_number = await helpers.getNextDocumentNumber("posts");
            formData.user = context.user;
            formData.user_id = context.user.id;
            formData.created_at = Timestamp.now();
            formData.updated_at = Timestamp.now();
            formData.search_keywords = helpers.generateSearchKeywords(formData.title);
            formData.slug = helpers.getSlug(formData.title);
            //add content
            formData.content = content;
            formData.status = formData.status ?? "publish";

            const postsRef = collection(db, "posts");
        
            //add timestamp to slug if slug already exists
            const slugQuery = query(postsRef, where("slug", "==", formData.slug));
            const slugQuerySnapshot = await getDocs(slugQuery);
            if (slugQuerySnapshot.size > 0) {
                formData.slug += "-" + formData.created_at.seconds;
            }

            //add to firebase
            await addDoc(postsRef, formData).then(async (docRef) => {
                //get the updated data
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setPost({ ...docSnap.data(), id: docSnap.id });
                    setPostData({ ...docSnap.data(), id: docSnap.id });
                    onModalUpdated({ ...docSnap.data(), id: docSnap.id });
                    toast.success("Added successfully");
                }
            }).catch((error) => {
                toast.error("Failed to add");
            });
        }

        toast.dismiss(loading);
        setShowModal(false);
    }



    return (
        <>
            <SkyeModal title={postData.id ? "Post" : "New Post"} size="max-w-7xl" flex={false} showModal={showModal} setShowModal={setShowModal}>
                <form className="space-y-6" id="post-edit-form" onSubmit={onFormSubmit} method="POST" encType="multipart/form-data">
                    {/*body*/}
                    <div className="relative p-6 flex-auto">

                        <div className="lg:grid lg:grid-cols-3 lg:gap-6">

                            {/* <!-- column 1 --> */}
                            <div className="mt-5 lg:mt-0 lg:col-span-2">

                                {/* <!-- INFO --> */}
                                <div className="shadow lg:rounded-md lg:overflow-visible">
                                    <div className="px-4 py-5 bg-white space-y-6 lg:p-6">

                                        <div className="">

                                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">News Title </label>
                                            <input
                                                name="title"
                                                value={postData.title || ""}
                                                onChange={onInputChanged}
                                                required
                                                type="text" id="title"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 font-bold text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="Title" autoComplete="off" />

                                        </div>





                                        <div className="h-[30rem]">
                                            <label htmlFor="content" className="block text-sm font-medium text-gray-700"> Content </label>
                                            <div className="mt-1">
                                            </div>
                                            <ReactQuill
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['link', 'image', 'video'],
                                                        ['clean'],
                                                        [{ color: [] }, { background: [] }],
                                                        [{ script: "sub" }, { script: "super" }],
                                                        ["blockquote", "code-block"],
                                                        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
                                                    ],
                                                }}
                                                id="content"
                                                value={content}
                                                onChange={setContent}
                                                placeholder="Type post content here..."
                                                className="h-96 rounded"
                                                theme="snow" />
                                        </div>


                                    </div>

                                </div>



                            </div>

                            {/* <!-- column 2 --> */}
                            <div className="lg:mt-0 lg:col-span-1">


                                {/* <!-- IMAGES --> */}
                                <div className="shadow lg:rounded-md lg:overflow-hidden mt-3">
                                    <div className="px-4 py-3 bg-gray-50 text-left lg:px-6">
                                        <h1>Cover image</h1>
                                    </div>

                                    <div className="px-4 py-5 bg-white space-y-6 lg:p-6">


                                        {/* <!-- image 1 --> */}
                                        <div>
                                            <div className="relative justify-center items-center w-full text-center h-44 lg:h-56">
                                                {
                                                    (() => {
                                                        if (coverImageFile) {
                                                            return (
                                                                <label htmlFor="image_file" style={{ backgroundImage: "url('" + URL.createObjectURL(coverImageFile) + "')" }} className="block cursor-pointer bg-gray-200 bg-cover bg-center absolute l-0 t-0 w-full h-44 lg:h-56 text-right">
                                                                    <button onClick={clearImage} type="button" className="bg-white text-black rounded mr-2 mt-2 p-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <desc>Download more icon variants from https://tabler-icons.io/i/x</desc>
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                        </svg>
                                                                    </button>
                                                                    {/* same image file */}
                                                                    <input
                                                                        name="image_file"
                                                                        onChange={onFileInputChanged}
                                                                        data-url="" id="image_file" type="file" className="hidden" accept="image/*" />
                                                                </label>
                                                            );
                                                        } else if (postData.image) {
                                                            return (
                                                                <label htmlFor="image_file" style={{ backgroundImage: "url('" + postData.image + "')" }} className="block cursor-pointer bg-gray-200 bg-cover bg-center absolute l-0 t-0 w-full h-44 lg:h-56 text-right">
                                                                    <button onClick={clearImage} type="button" className="bg-white text-black rounded mr-2 mt-2 p-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <desc>Download more icon variants from https://tabler-icons.io/i/x</desc>
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                                        </svg>
                                                                    </button>
                                                                    {/* same image file */}
                                                                    <input
                                                                        name="image_file"
                                                                        onChange={onFileInputChanged}
                                                                        data-url="" id="image_file" type="file" className="hidden" accept="image/*" />
                                                                </label>
                                                            );
                                                        } else {
                                                            return (
                                                                <label htmlFor="image_file" className="flex flex-col justify-center items-center w-full h-44 lg:h-56 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                                    <div className="flex flex-col justify-center items-center pt-5 pb-6">
                                                                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> for Image</p>
                                                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF</p>
                                                                    </div>
                                                                    {/* same image file */}
                                                                    <input
                                                                        name="image_file"
                                                                        onChange={onFileInputChanged}
                                                                        data-url="" id="image_file" type="file" className="hidden" accept="image/*" />
                                                                </label>
                                                            );
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>



                                    </div>

                                </div>


                                {/* <!-- PUBLISH --> */}
                                <div className="shadow lg:rounded-md lg:overflow-hidden mt-3">
                                    <div className="px-4 py-3 bg-gray-50 text-left lg:px-6">
                                        <h1>Publish</h1>
                                    </div>

                                    <div className="px-4 py-5 bg-white space-y-6 lg:p-6">

                                        <div className="">
                                            <label htmlFor="status" className="block text-sm font-medium text-gray-700"> Status </label>
                                            <select
                                                name="status"
                                                value={postData.status || ""}
                                                onChange={onInputChanged}
                                                id="status"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required>
                                                <option value="publish">Publish</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right lg:px-6">
                                        <button type="submit" className="inline-flex justify-center text-lg w-full py-3 px-5 border border-transparent shadow-sm font-medium rounded-md text-white bg-black hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
                                            <span id="submit-text">{postData.id ? "Update Post" : "Add Post"}</span>
                                        </button>
                                    </div>
                                </div>


                            </div>



                        </div>


                    </div>
                    {/*footer*/}
                    {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                       
                    </div> */}
                </form>
            </SkyeModal>
        </>
    );
}

export default PostModal;