import { Button, IconButton } from "@mui/material";
import { useSmartLoadingDialogContext } from "../components/SmartLoadingDialog";
import { useCartContext } from "../hooks/useCart";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../..";
import { toast } from "react-toastify";
import { InvoiceDialog } from "../components/InvoiceDialog";
import { useRef } from "react";

export default function OrderPlaced() {
  const navigate = useNavigate();
  const smartLoading = useSmartLoadingDialogContext();
  const cartContext = useCartContext();

  const invoiceRef = useRef(null as any);

  const orderID = new URLSearchParams(window.location.search).get("order");
  const paymentID = new URLSearchParams(window.location.search).get("payment");

  const done = async () => {
    //clear cart
    smartLoading.show();
    await cartContext.clearCart();
    smartLoading.dismiss();
    navigate("/app/home#invoices");
  };
  const cancelOrder = async () => {
    if (!orderID) {
      toast.error("Invalid order ID");
      return;
    }

    smartLoading.show();

    //get order
    const orderRef = doc(db, "orders", orderID);
    const orderSnap = await getDoc(orderRef);
    if (!orderSnap.exists()) {
      toast.error("Order not found");
      return;
    }

    const order: any = { ...orderSnap.data(), id: orderSnap.id };

    //cancel order
    await updateDoc(doc(db, "orders", orderID), {
      status: "cancelled",
    });

    //cancel payment
    if (order.payment_id || paymentID) {
      await updateDoc(doc(db, "payments", order.payment_id || paymentID), {
        status: "cancelled",
      });
    }

    //loop through order items and return quantity to inventory
    order.items?.forEach(async (item: any) => {
      //get product
      const productRef = doc(db, "products", item.product_id ?? item.id);
      const productDoc = await getDoc(productRef);
      if (productDoc.exists()) {
        const productData = productDoc.data();
        //update product quantity
        await updateDoc(productRef, {
          quantity: productData.quantity + item.quantity,
        });
      }
    });

    smartLoading.dismiss();
    navigate(-1);
  };
  const sendInvoice = async () => {
    if (paymentID) {
      invoiceRef.current?.open(paymentID);
    }
  };

  const onInvoiceDone = () => {};

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full h-screen gap-3 px-10 sm:px-80">
        {/* done */}
        <div className="w-full flex flex-row items-center justify-end mt-5">
          <IconButton
            aria-label="cart"
            color="primary"
            onClick={() => {
              done();
            }}
          >
            {/* <SvgIcon fontSize="large"> */}
            {/* credit: plus icon from https://heroicons.com/ */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            {/* </SvgIcon> */}
          </IconButton>
        </div>

        {/* image */}
        <div className="bg-black p-3 rounded-full flex items-center justify-center">
          <img
            src="/assets/images/img_vector.svg"
            className="w-[120px] h-[120px] m-5"
            alt="Order Placed"
          />
        </div>
        {/* successufl */}
        <div className="text-3xl font-normal">Successful</div>
        <div className="text-sm font-normal">
          Order has been placed successfully
        </div>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px] !mt-2"
          onClick={() => {
            done();
          }}
        >
          Done
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[50px] !mt-2 !bg-white !shadow-md"
          onClick={() => {
            sendInvoice();
          }}
          disableElevation={true}
        >
          Send invoice
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          className="!px-6 !rounded-xl !normal-case !text-lg !w-full !h-[40px] !mt-2 !bg-white !underline"
          disableElevation={true}
          onClick={() => {
            cancelOrder();
          }}
        >
          Cancel Order
        </Button>
      </div>
      <InvoiceDialog refx={invoiceRef} done={onInvoiceDone} />
    </>
  );
}
