/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../..";
import CartContext from "../CartContext";
import useToken from "../../hooks/useToken";
// import AppContext from "../../AppContext";

export const useCart = () => {
  
  const { token } = useToken();

  const [cart, setCart] = useState({}); //this is the user from the server, always reload from the server
  const [loading, setLoading] = useState(true);
  const [totalLoading, setTotalLoading] = useState(true);

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = async () => {
    if (!token) {
      setLoading(false);
      return;
    }
    setLoading(true);
    setTotalLoading(true);
    const cartRef = collection(db, "carts");
    const q = query(cartRef, where("sales_manager_id", "==", token));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      setCart({
        ...querySnapshot.docs[0].data(),
        id: querySnapshot.docs[0].id,
      });
    }
    setLoading(false);
    setTotalLoading(false);
  };

  const addToCart = async (
    productId: string,
    quantity: number,
    buyingPrice: number,
    selectedAttributes: any,
    note?: string
  ) => {
    setTotalLoading(true);

    //get product
    const ref = doc(db, "products", productId);
    const productDoc = await getDoc(ref);
    if (!productDoc.exists()) {
      setTotalLoading(false);
      console.log("No such product!");
      return;
    }

    var product: any = { ...productDoc.data(), id: productDoc.id };

    //get cart
    const cartRef = collection(db, "carts");
    const q = query(cartRef, where("sales_manager_id", "==", token));
    var querySnapshot = await getDocs(q);
    var cartDoc: any = {};
    //if cart is empty, create a new cart
    if (querySnapshot.empty) {
      const newCart = {
        sales_manager_id: token,
        items: [],
        subtotal: 0.0,
        total: 0.0,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };
      await addDoc(collection(db, "carts"), newCart);
      querySnapshot = await getDocs(q);
      cartDoc = querySnapshot.docs[0];
    } else {
      cartDoc = querySnapshot.docs[0];
    }

    var cartData = { ...cartDoc.data(), id: cartDoc.id };

    //check if product already exists in cart
    var productExists = false;
    var productIndex = -1;
    cartData.items.forEach((item: any, index: number) => {
      if (item.product_id === product.id) {
        productExists = true;
        productIndex = index;
      }
    });

    if (productExists) {
      //update item
      cartData.items[productIndex] = {
        id: product.id || null,
        product_id: product.id || null,
        doc_number: product.doc_number || null,
        name: product.name || null,
        slug: product.slug || null,
        description: product.description || null,
        actual_price: product.price || 0,
        price: buyingPrice || 0,
        quantity: quantity || 0,
        stock_quantity: product.quantity || 0,
        subtotal: buyingPrice * quantity,
        has_attributes: product.has_attributes || false,
        selected_attributes: selectedAttributes || {},
        has_variations: product.has_variations || false,
        selected_variation: {},
        user: product.user || {},
        categories: product.categories || [],
        images: product.images || [],
        status: product.status || "published",
        rating: product.rating || 0,
        rating_count: product.rating_count || 0,
        note: note || null,
      };
    } else {
        //add new item
        cartData.items.push({
          id: product.id || null,
          product_id: product.id || null,
          doc_number: product.doc_number || null,
          name: product.name || null,
          slug: product.slug || null,
          description: product.description || null,
          actual_price: product.price || 0,
          price: buyingPrice || 0,
          quantity: quantity || 0,
          stock_quantity: product.quantity || 0,
          subtotal: buyingPrice * quantity,
          has_attributes: product.has_attributes || false,
          selected_attributes: selectedAttributes || {},
          has_variations: product.has_variations || false,
          selected_variation: {},
          user: product.user || {},
          categories: product.categories || [],
          images: product.images || [],
          status: product.status || "published",
          rating: product.rating || 0,
          rating_count: product.rating_count || 0,
          note: note || null,
        });
    }

    //update cart subtotal
    var subtotal = 0;
    cartData.items.forEach((item: any) => {
      subtotal = subtotal + item.subtotal;
    });
    cartData.subtotal = subtotal;
    //update cart total
    cartData.total = subtotal;
    
    
    //update cart
    await updateDoc(doc(db, "carts", cartData.id), {
      ...cartData,
        updated_at: serverTimestamp(),
    });

    setCart(cartData);
    setTotalLoading(false);
  };

  const addCustomToCart = async (product: any, quantity: number, buyingPrice: number, note?: string) => {  
    setTotalLoading(true);

    //get cart
    const cartRef = collection(db, "carts");
    const q = query(cartRef, where("sales_manager_id", "==", token));
    var querySnapshot = await getDocs(q);
    var cartDoc: any = {};
    //if cart is empty, create a new cart
    if (querySnapshot.empty) {
      const newCart = {
        sales_manager_id: token,
        items: [],
        subtotal: 0.0,
        total: 0.0,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      };
      await addDoc(collection(db, "carts"), newCart);
      querySnapshot = await getDocs(q);
      cartDoc = querySnapshot.docs[0];
    } else {
      cartDoc = querySnapshot.docs[0];
    }

    var cartData = { ...cartDoc.data(), id: cartDoc.id };

    //check if product already exists in cart
    var productExists = false;
    var productIndex = -1;
    cartData.items.forEach((item: any, index: number) => {
      if (item.magic_number === product.magic_number) {
        productExists = true;
        productIndex = index;
      }
    });

    if (productExists) {
      //update item
      cartData.items[productIndex] = {
        magic_number: product.magic_number || null,
        name: product.name || null,
        type: product.type || null,
        description: product.description || null,
        actual_price: product.price || 0,
        price: buyingPrice || 0,
        quantity: quantity || 0,
        subtotal: buyingPrice * quantity,
        has_attributes: product.has_attributes || false,
        selected_attributes: {},
        has_variations: product.has_variations || false,
        selected_variation: {},
        user: product.user || {},
        categories: product.categories || [],
        images: product.images || [],
        status: product.status || "active",
        rating: product.rating || 0,
        rating_count: product.rating_count || 0,
        note: note || null,
      };
    } else {
        //add new item
        cartData.items.push({
          magic_number: product.magic_number || null,
          name: product.name || null,
          type: product.type || null,
          description: product.description || null,
          actual_price: product.price || 0,
          price: buyingPrice || 0,
          quantity: quantity || 0,
          subtotal: buyingPrice * quantity,
          has_attributes: product.has_attributes || false,
          selected_attributes: {},
          has_variations: product.has_variations || false,
          selected_variation: {},
          user: product.user || {},
          categories: product.categories || [],
          images: product.images || [],
          status: product.status || "active",
          rating: product.rating || 0,
          rating_count: product.rating_count || 0,
          note: note || null,
        });
    }

    //update cart subtotal
    var subtotal = 0;
    cartData.items.forEach((item: any) => {
      subtotal = subtotal + item.subtotal;
    });
    cartData.subtotal = subtotal;
    //update cart total
    cartData.total = subtotal;
    
    //update cart
    await updateDoc(doc(db, "carts", cartData.id), {
      ...cartData,
        updated_at: serverTimestamp(),
    });

    setCart(cartData);
    setTotalLoading(false);
  }

  const removeFromCart = async (productId: string) => {
    setTotalLoading(true);
    //get cart
    const cartRef = collection(db, "carts");
    const q = query(cartRef, where("sales_manager_id", "==", token));
    var querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const cartDoc = querySnapshot.docs[0];
      var cartData: any = { ...cartDoc.data(), id: cartDoc.id };
      //remove item
      cartData.items = cartData.items.filter(
        (item: any) => item.product_id !== productId
      );
      //update cart subtotal
      var subtotal = 0;
      cartData.items.forEach((item: any) => {
        subtotal = subtotal + item.subtotal;
      });
      cartData.subtotal = subtotal;
      //update cart total
      cartData.total = subtotal;
      //update cart
      await updateDoc(doc(db, "carts", cartData.id), {
        ...cartData,
        updated_at: serverTimestamp(),
      });
      setCart(cartData);
    }
    setTotalLoading(false);
  }

  const removeCustomFromCart = async (magicNumber: number) => {
    setTotalLoading(true);
    //get cart
    const cartRef = collection(db, "carts");
    const q = query(cartRef, where("sales_manager_id", "==", token));
    var querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const cartDoc = querySnapshot.docs[0];
      var cartData: any = { ...cartDoc.data(), id: cartDoc.id };
      //remove item
      cartData.items = cartData.items.filter(
        (item: any) => item.magic_number !== magicNumber
      );
      //update cart subtotal
      var subtotal = 0;
      cartData.items.forEach((item: any) => {
        subtotal = subtotal + item.subtotal;
      });
      cartData.subtotal = subtotal;
      //update cart total
      cartData.total = subtotal;
      //update cart
      await updateDoc(doc(db, "carts", cartData.id), {
        ...cartData,
        updated_at: serverTimestamp(),
      });
      setCart(cartData);
    }
    setTotalLoading(false);
  }

  const clearCart = async () => {
    setLoading(true);
    setTotalLoading(true);
    //get cart
    const cartRef = collection(db, "carts");
    const q = query(cartRef, where("sales_manager_id", "==", token));
    var querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const cartDoc = querySnapshot.docs[0];
      //delete cart
      await deleteDoc(doc(db, "carts", cartDoc.id));
    }
    setCart({});
    setLoading(false);
    setTotalLoading(false);
  }

  return {
    cart,
    loading,
    fetchCart,
    setCart,
    addToCart,
    addCustomToCart,
    totalLoading,
    clearCart,
    removeFromCart,
    removeCustomFromCart,
  };
};

type CartContextProps = () => {
  cart: any;
  loading: boolean;
  fetchCart: () => void;
  setCart: (cart: any) => void;
  addToCart: (
    productId: string,
    quantity: number,
    buyingPrice: number,
    selectedAttributes: any,
    note?: string
  ) => Promise<void>;
  addCustomToCart: (product: any, quantity: number, buyingPrice: number) => Promise<void>;
  clearCart: () => Promise<void>;
  totalLoading: boolean;
  removeFromCart: (productId: string) => Promise<void>;
  removeCustomFromCart: (magicNumber: number) => Promise<void>;
};
export const useCartContext: CartContextProps  = () => {
  return useContext(CartContext);
};
