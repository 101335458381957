import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useCartContext } from "../hooks/useCart";
import { Button } from "@mui/material";
import { Shimmer } from "react-shimmer";
import CheckoutItemCard from "../components/CheckoutItemCard";
import { helpers } from "../../helpers";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../..";
import { useSmartLoadingDialogContext } from "../components/SmartLoadingDialog";
import AppContext from "../../AppContext";

export default function Checkout() {
  const navigate = useNavigate();

  const context: any = useContext(AppContext);

  const cartContext = useCartContext();

  const smartLoading = useSmartLoadingDialogContext();

  const [client, setClient] = useState({} as any);
  // const [isNewClient, setIsNewClient] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);

  const fetchClient = async () => {
    const clientID = new URLSearchParams(window.location.search).get("client");
    if (clientID) {
      setClientLoading(true);
      const docRef = doc(db, "clients", clientID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setClient({ ...docSnap.data(), id: docSnap.id });
      } else {
        toast.error("Client not found");
        navigate(-1);
      }
      setClientLoading(false);
    } else {
      const fullName = new URLSearchParams(window.location.search).get(
        "full_name"
      );
      const phone = new URLSearchParams(window.location.search).get("phone");
      const state = new URLSearchParams(window.location.search).get("state");
      const address = new URLSearchParams(window.location.search).get(
        "address"
      );
      const email = new URLSearchParams(window.location.search).get("email");
      const city = new URLSearchParams(window.location.search).get("city");
      const country = new URLSearchParams(window.location.search).get(
        "country"
      );
      const zipCode = new URLSearchParams(window.location.search).get(
        "zip_code"
      );

      //either of fullName, phone or state must be present
      if (fullName || phone || state) {
        setClient({
          id: null,
          doc_number: null,
          full_name: fullName || "",
          phone: phone || "",
          state: state || "",
          address: address || "",
          email: email || "",
          city: city || "",
          country: country || "",
          zip_code: zipCode || "",
        });
      } else {
        toast.error("Client's full name, phone number or state is required");
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    cartContext.fetchCart();
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrder = async () => {
    smartLoading.show();

    try {
      //every possible search item
      const searchItems = [client.full_name];

      //create order
      const orderDocNumber = await helpers.getNextDocumentNumber("orders");

      var isANewClient = new URLSearchParams(window.location.search).get("is_new") === "true" || false; // isNewClient;

       //check if client is new
       if (client.id /* && !isNewClient*/ ) {
        const docRef = doc(db, "clients", client.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          var clientData = docSnap.data();
          isANewClient = clientData?.is_new || false;
          // //if client.create_at difference with now is less than 1 day, then client is not new
          // const now = new Date();
          // const createdAt = clientData?.created_at?.toDate() || new Date();
          
          // var diffTime = Math.abs(now.getTime() - createdAt.getTime());
          // var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          // if (diffDays > 1) {
          //   isANewClient = false;
          // } else {
          //   isANewClient = true;
          // }

        }
      }


      var order: any = {
        doc_number: orderDocNumber,
        client_id: client.id || null,
        client_full_name: client.full_name,
        client: client,
        is_new_client: isANewClient,
        items: cartContext.cart.items.map((item: any) => {
          return {
            ...item,
            backorder_quantity:
              (item.stock_quantity || 0) - item.quantity < 0
                ? Math.abs((item.stock_quantity || 0) - item.quantity)
                : 0,
          };
        }),
        subtotal: cartContext.cart.subtotal,
        total: cartContext.cart.total,
        user_id: client.id || null,
        user: client,
        sales_manager_id: context.user?.id,
        sales_manager: context.user,
        status: "pending",
        payment_status: "pending",
        search_keywords: helpers.generateSearchKeywords(
          searchItems.map((s) => s.toLowerCase()).join(" ")
        ),
        updated_at: serverTimestamp(),
        created_at: serverTimestamp(),
      };
      const addOrder = await addDoc(collection(db, "orders"), order);
      order.id = addOrder.id;

      //create payment
      const paymentDocNumber = await helpers.getNextDocumentNumber("payments");
      var payment: any = {
        doc_number: paymentDocNumber,
        order_id: order.id,
        order: order,
        order_doc_number: order.doc_number,
        order_status: order.status,
        client_id: client.id || null,
        client_full_name: client.full_name,
        client: client,
        is_new_client: isANewClient,
        user_id: client.id || null,
        user: client,
        sales_manager_id: context.user?.id,
        sales_manager: context.user,
        status: "pending",
        payment_status: "pending",
        payment_for: "order",
        amount: cartContext.cart.total,
        total_paid: 0, //initially
        description: `Payment for order #${order.doc_number} by ${client.full_name}`,
        search_keywords: helpers.generateSearchKeywords(
          searchItems.map((s) => s.toLowerCase()).join(" ")
        ),
        updated_at: serverTimestamp(),
        created_at: serverTimestamp(),
      };
      const addPayment = await addDoc(collection(db, "payments"), payment);
      payment.id = addPayment.id;

      //update order with payment id
      await updateDoc(doc(db, "orders", addOrder.id), {
        payment_id: payment.id,
        payment: payment,
        payment_status: payment.status || "pending",
      });

      //update product quantity
      for (const item of cartContext.cart.items) {
        if (item.type !== "custom" && item.id !== "0") {
          //get product
          const productDocRef = doc(db, "products", item.product_id || item.id);
          const productDocSnap = await getDoc(productDocRef);
          var product: any = { ...productDocSnap.data(), id: productDocSnap.id };
          var qtyLeft = product.quantity - item.quantity;
          if (qtyLeft < 0) {
            qtyLeft = 0;
          }
          //update product
          await updateDoc(doc(db, "products", product.id), {
            quantity: qtyLeft,
          });
        }
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred");
      smartLoading.dismiss();
      return;
    }

    smartLoading.dismiss();

    navigate(`/app/order-placed?order=${order.id}&payment=${payment.id}`);
  };

  return (
    <>
      {/* header */}
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* back button */}
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos />
              </IconButton>
            </li>
          </ul>
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full">
            Checkout
          </p>
          <div className="w-12"></div>
        </div>
      </header>
      <div className="mt-[90px]"></div>

      {cartContext.loading ? (
        <ShimmerPage />
      ) : (
        <div className="px-4 sm:grid sm:grid-cols-2 sm:gap-x-10 sm:px-20">

          <div>
          {/* empty cart placeholder */}
          {cartContext.cart.items?.length < 1 && (
            <div className="flex flex-col items-center justify-center gap-y-2 mt-[150px] mb-[100px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[80px] h-[80px] text-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                />
              </svg>
              <div className="text-2xl text-gray-400 font-normal">
                Cart is empty
              </div>
            </div>
          )}

          {/* cart items */}
          {cartContext.cart.items?.length > 0 && (
            <div className="mb-4 space-y-2">
              {cartContext.cart.items.map((item: any) => {
                return <CheckoutItemCard key={item.id} item={item} showAttributes={true} />;
              })}
            </div>
          )}
          </div>
          <div>
          {/* total */}
          {cartContext.totalLoading ? (
            <Shimmer
              height={40}
              width={500}
              className="!rounded-lg !w-full !my-4"
            />
          ) : (
            <div className="flex flex-row items-center justify-between gap-x-2 my-2">
              <div className="text-xl font-medium leading-tight w-full">
                Total
              </div>
              <div className="text-2xl font-bold py-0.5 px-3">
                N{helpers.money(cartContext.cart.total)}
              </div>
            </div>
          )}

          {/* client info */}
          {clientLoading ? (
            <Shimmer
              height={100}
              width={500}
              className="!rounded-lg !w-full !my-4"
            />
          ) : (
            <div className="my-4 border border-gray-300 p-3 flex flex-col gap-y-2">
              <div className="flex flex-row items-center justify-between gap-x-10">
                <div className="font-normal text-gray-600">Name</div>
                <div className="font-bold">{client?.full_name}</div>
              </div>
              <div className="flex flex-row items-center justify-between gap-x-10">
                <div className="font-normal text-gray-600">Phone</div>
                <div className="font-bold">{client?.phone}</div>
              </div>
              <div className="flex flex-row items-center justify-between gap-x-10">
                <div className="font-normal text-gray-600">Address</div>
                <div className="font-bold">
                  {client?.address} {client?.state}
                </div>
              </div>
            </div>
          )}

            <div className={`text-xs text-gray-500 text-center ${!(client.is_new || new URLSearchParams(window.location.search).get("is_new") === "true" || false) && "hidden"}`}>
              This client has been identified as a <b>new client</b>
            </div>

          {/* is new client toggle button */}
          {/* <div className="flex flex-col">
            <div className="flex flex-row items-center justify-center gap-x-5">
              <div className="font-bold text-black">Is new client?</div>
              <Switch checked={isNewClient} onChange={() => setIsNewClient(!isNewClient)} />
            </div>
            <div className="text-xs text-gray-500 text-center">
              You can specify if the client is new or not
            </div>
          </div> */}

          {/* checkout button */}
          <Button
            variant="contained"
            size="small"
            color="primary"
            className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px] !mt-2"
            onClick={() => {
              if (
                cartContext.cart.items?.length < 1 ||
                cartContext.loading ||
                cartContext.totalLoading ||
                cartContext.cart.total < 1 ||
                clientLoading
              ) {
                if (cartContext.cart.items?.length < 1) {
                  toast.error("Cart is empty");
                } else if (cartContext.loading || cartContext.totalLoading) {
                  toast.error("Please wait for cart to load");
                } else if (cartContext.cart.total < 1) {
                  toast.error("Invalid cart total calculated");
                }
                return;
              }
              createOrder();
            }}
            disabled={
              cartContext.cart.items?.length < 1 ||
              cartContext.loading ||
              cartContext.totalLoading ||
              cartContext.cart.total < 1 ||
              clientLoading
            }
          >
            Complete Order
          </Button>

          </div>

        </div>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

const ShimmerPage = () => {
  return (
    <>
      {/* page */}
      <div className="px-4 space-y-4 mt-[100px] sm:grid sm:grid-cols-2 sm:gap-x-10 sm:px-20">
        {/* cart items */}
        <div className="space-y-2">
          {[1, 2, 3, 4].map((item: any) => {
            return (
              <Shimmer
                key={item}
                width={130}
                height={120}
                className="rounded-lg !w-full"
              />
            );
          })}
        </div>
        <div className="sm:flex sm:flex-col sm:gap-y-5">
        {/* cart total */}
        <Shimmer width={130} height={50} className="rounded-lg !w-full" />
        {/* checkout button */}
        <Shimmer
          width={130}
          height={50}
          className="rounded-lg !w-full !h-[60px]"
        />
        </div>
      </div>
    </>
  );
};
