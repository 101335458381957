

import React, { useState, useEffect } from "react";
import MainTemplate from "./MainTemplate";
import useDocumentTitle from "../hooks/userDocumentTitle";
import PostsTable from "../components/PostsTable";
import { db } from "..";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

const Posts = () => {
    useDocumentTitle("Posts");
    const [posts, setPosts] = useState([] as any[]);
    const [postsLoading, setPostsLoading] = useState(false);
    const fetchPosts = async () => {
        setPostsLoading(true);
        let posts: any[] = [];
        //fetch orders from firebase
        const postsRef = collection(db, 'posts');
        const q = query(postsRef, orderBy('created_at', 'desc')  );
        await getDocs(q).then((snapshot) => {
            snapshot.forEach((doc) => {
                posts.push({ ...doc.data(), id: doc.id });
            });
        });
        setPosts(posts);
        setPostsLoading(false);
    };

    useEffect(() => {
        fetchPosts();
    }, []);


    const onTableUpdated = (post: any) => {
        fetchPosts();
    }


    return (
        <MainTemplate title="Posts">

            <PostsTable posts={posts} onTableUpdated={onTableUpdated} loading={postsLoading} />

        </MainTemplate>
    );
}

export default Posts;